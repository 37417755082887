import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';



const theme = createTheme();

export default function Login() {

  const navigate = useNavigate()
  const handleSubmit = (event) => {
    event.preventDefault();


    const data = new FormData(event.currentTarget);


    const jsondata = JSON.stringify({
      tel: data.get('tel'),
      password: data.get('password'),
    });


    var config = {
      method: 'post',
      url: 'https://testapi.thaipadi.org/login',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : jsondata
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK'){
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('TN', response.data.TN);
        localStorage.setItem('fname', response.data.fname);
        localStorage.setItem('lname', response.data.lname);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('prefix', response.data.prefix);
        if(response.data.role === 'admin' || response.data.role === 'root' ){
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'ยินดีตอนรับสู่ THAIPADI',
            showConfirmButton: false,
            timer: 1500
          }).then((result)=>{
            navigate('/backhome')
          })
        } else{
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'ยินดีตอนรับสู่ THAIPADI',
            showConfirmButton: false,
            timer: 1500
          }).then((result)=>{
            navigate('/')
          })
        }
      } else{
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });


  };





  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="tel"
              label="เบอร์โทรศัพท์"
              name="tel"
              type="number"
              autoComplete="tel"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="รหัสผ่าน"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              เข้าสู่ระบบ
            </Button>
          </Box>
        </Box>
                  <Link to="/" variant="signupp" style={{textAlign: 'right' , textDecoration:'none' , color:'blue' }}>
                    <p>กลับหน้าหลัก</p> 
                  </Link>
            <hr style={{marginTop:'16px'}} />
            <h6 style={{marginTop:'8px' , textAlign:'center'}} >
            กรณีลืมรหัสผ่านให้ติดต่อเจ้าหน้าที่เพื่อรีซ็ตรหัสผ่าน
            </h6>
            <h6 style={{textAlign:'center'}}>
            0902099333
            </h6>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}