import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import './sidebar.css'

function Sidebar() {
  const tntoken = localStorage.getItem('token')

  const alertreviews =()=>{
    Swal.fire({
      icon: 'info',
      title: 'แจ้งเตือน',
      text: 'กรุณาเข้าสู่ระบบ ก่อนประเมินความพึงพอใจ',
      showConfirmButton: false,
      timer: 2500
    })
  }
  const alertstep =()=>{
    Swal.fire({
      icon: 'warning',
      title: 'แจ้งเตือน',
      text: 'คู่มือการให้บริการ ยังไม่พร้อมใช้งาน',
      showConfirmButton: false,
      timer: 2500
    })
  }
  if (tntoken !== null){
  return (
    <div className="box">
      <div className="content">
          <Link to="/calendar">ปฏิทินนัด</Link>
          <Link to="/search">ตรวจสอบนัด</Link>
          <Link to="/reviews">ประเมินความพึงพอใจ</Link>
          <Link to="/" onClick={alertstep} >คู่มือการใช้บริการ</Link>
      </div>
    </div>
  );
  }return (
    <div className="box">
      <div className="content">
          <Link to="/calendarusers">ปฏิทินนัด</Link>
          <Link to="/search">ตรวจสอบนัด</Link>
          <Link to="/" onClick={alertreviews}> ประเมินความพึงพอใจ</Link>
          <Link to="/" onClick={alertstep} >คู่มือการใช้บริการ</Link>
      </div>
    </div>
  );
}

export default Sidebar;