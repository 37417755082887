import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navback from './Navbarback';
import Swal from 'sweetalert2';




const Useredit = ()=>{
    const URL = 'https://testapi.thaipadi.org'
    useEffect(() => {
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: `${URL}/authen`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
        // alert('login success')
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' ))
        {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            window.location.href = "/";
          })
        }   
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            window.location.href = "/login";
          })
        }
      })
    .catch(function (error) {
      console.log(error);
    });

    }, [URL]);

    const { TN } = useParams()
    
    const [prefix, setPrefix] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [tel, setTel] = useState('');
    const [role, setRole] = useState('');
    const [TNN, setTNN] = useState(0);


    useEffect(()=>{
      var data = '';
      var config = {
        method: 'get',
        url: `${URL}/user/${TN}`,
        headers: { },
        data : data
      };

      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
            setPrefix(response.data[0].prefix)
            setFname(response.data[0].fname)
            setLname(response.data[0].lname)
            setTel(response.data[0].tel)
            setRole(response.data[0].role)
            setTNN(response.data[0].TN)
      })
      .catch(function (error) {
        console.log(error);
      });
    },[TN,URL])





    const prefixx=(event)=>{
        setPrefix(event.target.value)
    }

    
    const editform =(event)=>{
        event.preventDefault()
        axios
        .put(`${URL}/users/${TN}`, {
          TN: TNN,
          prefix: prefix,
          fname: fname,
          lname: lname,
          tel: tel,
          role: role,
        })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert(JSON.stringify(response.data))
            if (response.data['status'] === 'OK'){
              window.location.href = "/users";
                }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return(
        <React.Fragment>
        <Navback/>
        <CssBaseline />
        <Container maxWidth="sm" sx={{p:2}}>
          <Typography variant='h6' gutterBottom component="div">
                แก้ไขข้อมูล
          </Typography>
          <form onSubmit={editform}>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3} sm={3}>
                        <TextField id="prefix" label="คำนำหน้า" variant="outlined" fullWidth required onChange={prefixx} value={prefix}/>
                    </Grid>
                    <Grid item xs={4.5} sm={4.5}>
                        <TextField id="fname" label="ชื่อ" variant="outlined" fullWidth required onChange={(event)=>{setFname(event.target.value)}} value={fname}/>
                    </Grid>
                    <Grid item xs={4.5} sm={4.5}>
                        <TextField id="lname" label="นามสกุล" variant="outlined" fullWidth required onChange={(event)=>{setLname(event.target.value)}} value={lname}/>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField id="TN" label="TN" variant="outlined" fullWidth required onChange={(event)=>{setTNN(event.target.value)}} value={TNN}/>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField id="role" label="สิทธิ์" variant="outlined" fullWidth required onChange={(event)=>{setRole(event.target.value)}} value={role}/>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField id="tel" label="เบอร์โทรศัพท์" variant="outlined" fullWidth required onChange={(event)=>{setTel(event.target.value)}} value={tel}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Button type='submit' variant="contained" color="primary" size="large" fullWidth>ยืนยันการแก้ไข้ข้อมูล</Button>
                    </Grid>
                    {/* <Grid item xs={6} sm={6}>
                      <Link to='/users' style={{textDecoration:'none'}}><Button variant="contained" color="error" size="large" fullWidth>กลับหน้าแรก</Button></Link>
                    </Grid> */}
                </Grid>
            </Grid>
          </form>
        </Container>
      </React.Fragment>
    )
}
export default Useredit;
