import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Navback from "./Navbarback";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Container } from "@mui/material";
import { DeleteForever} from "@mui/icons-material";
import Swal from "sweetalert2";


const Cancelback=()=>{

  const URL = 'https://testapi.thaipadi.org'

  useEffect(() => {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: `${URL}/authen`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
        // alert('login success')
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' )){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้',
          showConfirmButton: false,
          timer: 3000
        }).then((result)=>{
          localStorage.clear();
          window.location.href = "/";
        })
      }   
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้',
          showConfirmButton: false,
          timer: 3000
        }).then((result)=>{
          localStorage.clear();
          window.location.href = "/login";
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });

    }, [URL]);





    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  



    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${URL}/cancellist`).then((res) => {
            setData(res.data);
            console.log(res.data);
          });
        
    }, [URL]);
  
  



    function cancel(TN) {
      var data = JSON.stringify({
        "TN": TN
      });
      
      var config = {
        method: 'delete',
        url: URL,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        alert(JSON.stringify(response.data));
        if (response.data['status'] === 'OK'){
          window.location = '/cancelback'
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      
    }
  
  
  
  
    return (
      <div>
        <Navback/>
        <Container maxWidth="lg" sx={{ p: 2 }}>
          <Paper>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ paddingRight: 2, paddingLeft: 2, borderRadius: 2 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <h2>เลขที่สมาชิก</h2>
                    </TableCell>
                    <TableCell align="center">
                      <h2>ชื่อ-สกุล</h2>
                    </TableCell>
                    <TableCell align="center">
                      <h2>เบอร์โทรศัพท์</h2>
                    </TableCell>
                    <TableCell align="center">
                      <h2>วันที่ยกเลิก</h2>
                    </TableCell>
                    <TableCell align="center">
                      <h2>สถานะ</h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => {
                    return (
                      <TableRow key={item.BN}>
                        <TableCell align="center">{item.TN}</TableCell>
                        <TableCell>
                          {item.prefix}
                          {item.fname} {item.lname}
                        </TableCell>
                        <TableCell align="center">{item.tel}</TableCell>
                        <TableCell align="center">{item.dateblock}</TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              return cancel(item.TN);
                            }}
                            startIcon={<DeleteForever />}
                            style={{ margin: "0 0 0 8px" }}
                            >
                            ยกเลิกการบล๊อค
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          {/* </div> */}
        </Container>
      </div>
    );
  }
export default Cancelback;
