import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Navbars.css'


const Navback = ()=>{

    const tntoken = localStorage.getItem("token");
    const roletoken = localStorage.getItem("role");

    const [isMobile,setIsMobile] = useState(!true) 


    if(tntoken !== null && ( roletoken === "root" || roletoken === "admin")){
        return(
        <nav className="navbar">
            <h3><Link to='/calendar' className="logo">THAI PADI</Link></h3>
            <ul className={isMobile? "nav-links-mobile":"nav-links"} onClick={()=>{setIsMobile(false)}}>
                <Link to='/backhome' className="cancel">
                    <li>ปฏิทินนัด</li>
                </Link>
                <Link to='/nextdays' className="home">
                    <li>วันถัดไป</li>
                </Link>
                <Link to='/users' className="calendar">
                    <li>สมาชิกทั้งหมด</li>
                </Link>
                <Link to='/block' className="about">
                    <li>ผิดนัดเกินกำหนด</li>
                </Link>
                <Link to='/' onClick={() => localStorage.clear()} className="logout">
                    <li>ออกจากระบบ</li>
                </Link>
            </ul>
            <button className="mobile-menu-icon" onClick={()=>setIsMobile(!isMobile)}>
                {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
            </button>
        </nav>
        )
    }

    return (
        <nav className="navbar">
            <h3><Link to='/calendar' className="logo">THAI PADI</Link></h3>
            <ul className={isMobile? "nav-links-mobile":"nav-links"} onClick={()=>{setIsMobile(false)}}>
            <Link to='/' className="home">
                    <li>หน้าหลัก</li>
                </Link>
                <Link to='/backhome' className="cancel">
                    <li>ปฏิทินนัด</li>
                </Link>
                <Link to='/users' className="calendar">
                    <li>สมาชิกทั้งหมด</li>
                </Link>
                <Link to='/block' className="about">
                    <li>ผิดนัดเกินกำหนด</li>
                </Link>
                <Link to='/login' className="login">
                    <li>เข้าสู่ระบบ
                         {/* | สมัครสมาชิก */}
                         </li>
                </Link>
            </ul>
            <button className="mobile-menu-icon" onClick={()=>setIsMobile(!isMobile)}>
                {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
            </button>
        </nav>
    )
}

export default Navback;
