import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import axios from "axios";
import Navbar from './Navbar/Navbar';
import Footer from './footer/footer';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//         หากลืมรหัสผ่านกรุณาติดต่อเจ้าหน้าที่: 0902099333
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const theme = createTheme();






export default function Cpass() {
  const URL = "https://testapi.thaipadi.org"
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem("token");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          // alert('login success')
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'กรุณาเข้าสู่ระบบก่อนใช้งาน',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            navigate("/login");
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate,URL]);


  const tntoken = localStorage.getItem("TN")
  const [ newpassword1 , setNewpassword1] = useState()
  const  password1 = (e) =>{
      e.preventDefault()
      setNewpassword1(e.target.value)
  }
  const [ newpassword2 , setNewpassword2] = useState()
  const  password2 = (e) =>{
      e.preventDefault()
      setNewpassword2(e.target.value)
  }

  const checkpassword =(e)=>{
    e.preventDefault()
    if(newpassword1 !== newpassword2){
      Swal.fire({
        icon: 'error',
        title: 'รหัสผ่านไม่ตรงกัน',
        text:'กรุณากรอกรหัสผ่านให้ตรงกัน',
        showConfirmButton: false,
        timer: 2000
      })
    }else{
      handleSubmit()
    }
}  

  const handleSubmit = () => {

    var data = JSON.stringify({
      "TN": tntoken,
      "password": newpassword1
    });
    
    var config = {
      method: 'put',
      url: `${URL}/changepassword`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      Swal.fire({
        icon: 'success',
        title: 'เสร็จสิ้น',
        text:'เปลี่ยนรหัสผ่านเรียบร้อย',
        showConfirmButton: false,
        timer: 2000
      }).then((result)=>{
        localStorage.clear()
        navigate("/login");
      })
    })
    .catch(function (error) {
      console.log(error);
    });


  };





  return (
    <div>
    <Navbar/>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
    <Paper style={{marginBottom: '80px' , marginTop: '100px' , marginRight:'16px' , marginLeft:'16px'}}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            เปลี่ยนรหัสผ่าน
          </Typography>
        <CssBaseline />
        <Container maxWidth="sm" sx={{p:2}}>
          <form onSubmit={checkpassword}>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField id="newpass" type="password" label="รหัสผ่านใหม่" variant="outlined" fullWidth required onChange={password1}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField id="cnewpass" type="password" label="ยืนยันรหัสผ่านใหม่" variant="outlined" fullWidth required onChange={password2}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Button type='submit' variant="contained" color="primary" size="large" fullWidth>ยืนยัน</Button>
                    </Grid>
                </Grid>
            </Grid>
          </form>
        </Container>
        </Box>
      </Paper>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
    <Footer/>
    </div>
  );
}