import Navbar from "./Navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";
import { Button, CssBaseline, Container,TextField } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import Footer from "./footer/footer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";

const Search = () => {
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const navigate = useNavigate()
  const URL = "https://testapi.thaipadi.org"

  useEffect(() => {
    const token = localStorage.getItem("token");

    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          // alert('login success')
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'กรุณาเข้าสู่ระบบก่อนใช้งาน',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            navigate("/login");
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate,URL]);



  const [mandata, setMandata] = useState([]);
  const mandataL = mandata.length
  const [data, setData] = useState([]);
  const dataL = data.length
  const [datas, setDatas] = useState([]);
  const datasL = datas.length
  const [datass, setDatass] = useState([]);
  const datassL = datass.length

  //จำนวนครั้งที่นัดหมาย
    const Apl = mandataL+dataL+datasL+datassL
  //0ำนวนครั้งที่นัดหมาย


  const getdata = ()=>{
    const tntoken = localStorage.getItem("TN");
    const usersURL1 = `${URL}/manappoinment/`;
    const usersURL2 = `${URL}/appoinment/`;
    const usersURL3 = `${URL}/appoinments/`;
    const usersURL4 = `${URL}/appoinmentss/`;
    axios.get(`${usersURL1}${tntoken}`).then((res) => {
      setMandata(res.data);
    });
    axios.get(`${usersURL2}${tntoken}`).then((res) => {
      setData(res.data);
    });
    axios.get(`${usersURL3}${tntoken}`).then((res) => {
      setDatas(res.data);
    });
    axios.get(`${usersURL4}${tntoken}`).then((res) => {
      setDatass(res.data);
    });
  }
  //ดึงข้อมูลผิดนัด
  const [datablock, setDatablock] = useState([]);
  const getblock = ()=>{
    const tntoken = localStorage.getItem("TN");
    const usersURL1 = `${URL}/blocklist/`;
    axios.get(`${usersURL1}${tntoken}`).then((res) => {
      setDatablock(res.data);
    });
  }
  //ดึงข้อมูลผิดนัด

  useEffect(() => {
    getdata()
    getblock()
  }, []);

  //cancel
  //คนที่1


  const confirmcancel1 = (AN) =>{
    Swal.fire({
      title: 'คำเตือน',
      text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยกเลิกนัดหมาย',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(AN)
      }
    })
  }
  
  const canceldata = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomen`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if((response.data["status"] === "OK")){
          Swal.fire({
            icon: 'success',
            title: 'เสร็จสิ้น',
            text:'ยกเลิกนัดหมายเรียบร้อย',
            showConfirmButton: false,
            timer: 2000
          })
          .then((result)=>{
            getdata()
        })
      } else {
        alert(JSON.stringify(response.data))
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  };
  //คนที่1
  //คนที่2

  const confirmcancel2 = (AN) =>{
    Swal.fire({
      title: 'คำเตือน',
      text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยกเลิกนัดหมาย',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(AN)
      }
    })

  }

  const canceldatas = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomens`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if((response.data["status"] === "OK")){
          Swal.fire({
            icon: 'success',
            title: 'เสร็จสิ้น',
            text:'ยกเลิกนัดหมายเรียบร้อย',
            showConfirmButton: false,
            timer: 2000
          })
          .then((result)=>{
            getdata()
        })
      } else {
        alert(JSON.stringify(response.data))
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  //คนที่2
  //คนที่3

  const confirmcancel3 = (AN) =>{
    Swal.fire({
      title: 'คำเตือน',
      text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยกเลิกนัดหมาย',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(AN)
      }
    })

  }

  const canceldatass = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomenss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if((response.data["status"] === "OK")){
          Swal.fire({
            icon: 'success',
            title: 'เสร็จสิ้น',
            text:'ยกเลิกนัดหมายเรียบร้อย',
            showConfirmButton: false,
            timer: 2000
          })
          .then((result)=>{
            getdata()
        })
      } else {
        alert(JSON.stringify(response.data))
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  };
  //คนที่3
  //คนที่4
  // const [ancancel4, setANcancel4] = useState(null);
  // const [open4, setOpen4] = useState(false);


  // const handleOpen4 = (AN) => {
  //   setANcancel4(AN);
  //   setOpen4(true);
  //   return;
  // };
  // const handleClose4 = () => setOpen4(false);

  const confirmcancel4 = (AN) =>{
    Swal.fire({
      title: 'คำเตือน',
      text: "การยกเลิกนัดหมายติดต่อกันหลายครั้ง มีผลต่อสิทธิ์ในการนัดหมายของท่าน",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยกเลิกนัดหมาย',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(AN)
      }
    })

  }

  const canceldataman = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearman`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if((response.data["status"] === "OK")){
          Swal.fire({
            icon: 'success',
            title: 'เสร็จสิ้น',
            text:'ยกเลิกนัดหมายเรียบร้อย',
            showConfirmButton: false,
            timer: 2000
          })
          .then((result)=>{
            getdata();
        })
      } else {
        alert(JSON.stringify(response.data))
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  //คนที่4
  //canceldata

  // ดึงข้อมูลนัดหมาย
  const [appdata, setAppdata] = useState([]);
  const appdataone = () => {
    const usersURL = `${URL}/appoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdata(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdatas, setAppdatas] = useState([]);
  const appdatatwo = () => {
    const usersURL = `${URL}/appoinments`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatas(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdatass, setAppdatass] = useState([]);
  const appdatatree=() => {
    const usersURL = `${URL}/appoinmentss`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdatass(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }

  const [appdataman, setAppdataman] = useState([]);
  const appdatafour = () => {
    const usersURL = `${URL}/manappoinment`;
    axios.get(`${usersURL}`).then((res) => {
      setAppdataman(
        res.data.filter((e) => {
          return e.TN > 9;
        })
      );
    });
  }


  useEffect(() => {
    appdataone()
    appdatatwo()
    appdatatree()
    appdatafour()
  }, []);
  
  console.log(appdataman)

  const appalldata = [...appdata, ...appdatas, ...appdatass, ...appdataman];

  const [appsearch, setAppsearch] = useState(null);
  const apptesttt = (e) => {
    if(e.target.value === ''){
      return setAppsearch(null)
    }
    else{
      return setAppsearch(e.target.value);
    }
  };
  // ดึงข้อมูลนัดหมาย

  return (
    <div>
      <Navbar />
      <CssBaseline />
      <Container>
        <Box>
          <Paper
            style={{
              marginBottom: "80px",
              marginTop: "100px",
              marginRight: "16px",
              marginLeft: "16px",
            }}
          >
            <div style={{ marginBottom: "16px" }}>
              <h2 style={{ textAlign: "center" }}>ปฏิทินนัดนวด</h2>
              <h3 style={{ textAlign: "center", color: "black" }}>
                คุณ{fnametoken} {lnametoken}
              </h3>
              <h4 style={{ textAlign: "center", color: "black" }}>
                จำนวนที่นัดทั้งหมด = {Apl}
              </h4>
              <h5 style={{ textAlign: "center", color: "black" }}>
                กรุณาตรวจสอบให้ชัดเจนเนื่องจากไม่ได้เรียงตามวันที่
              </h5>
            </div>
            <TableContainer component={Paper}>
              <table>
                <thead>
                  <tr className="head">
                    <td>
                      <h4>วันที่</h4>
                    </td>
                    <td>
                      <h4>เวลา</h4>
                    </td>
                    <td>
                      <h4>เจ้าหน้าที่</h4>
                    </td>
                    <td>
                      <h4>สถานะ</h4>
                    </td>
                  </tr>
                </thead>

                <tbody className="search">
                  {/* คนที่1 */}
                  {data.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.provider}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          startIcon={<CancelOutlined />}
                          style={{ margin: "0 0 0 4px" }}
                          // onClick={()=>{
                          //   return canceldata(row.AN)
                          // }}
                          onClick={() => {
                            return confirmcancel1(row.AN);
                          }}
                        >
                          ยกเลิก
                        </Button>
                      </td>
                    </tr>
                  ))}
                  {/* คนที่2 */}
                  {datas.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.provider}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          startIcon={<CancelOutlined />}
                          style={{ margin: "0 0 0 4px" }}
                          // onClick={()=>{
                          //   return canceldata(row.AN)
                          // }}
                          onClick={()=>{return confirmcancel2(row.AN)}}
                        >
                          ยกเลิก
                        </Button>
                      </td>
                    </tr>
                  ))}
                  {/* คนที่3 */}
                  {datass.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.provider}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          startIcon={<CancelOutlined />}
                          style={{ margin: "0 0 0 4px" }}
                          // onClick={()=>{
                          //   return canceldata(row.AN)
                          // }}
                          onClick={()=>{return confirmcancel3(row.AN)}}
                        >
                          ยกเลิก
                        </Button>
                      </td>
                    </tr>
                  ))}
                  {/* คนที่4 */}
                  {mandata.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.provider}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          startIcon={<CancelOutlined />}
                          style={{ margin: "0 0 0 4px" }}
                          // onClick={()=>{
                          //   return canceldata(row.AN)
                          // }}
                          onClick={()=>{return confirmcancel4(row.AN)}}
                        >
                          ยกเลิก
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </Paper>
        </Box>
        <Box>
          <Paper
            style={{
              marginBottom: "80px",
              marginTop: "100px",
              marginRight: "16px",
              marginLeft: "16px",
            }}
          >
            <div style={{ marginBottom: "16px" }}>
              <h2 style={{ textAlign: "center" }}>ประวัติการผิดนัดหมาย</h2>
              <h3 style={{ textAlign: "center", color: "black" }}>
                คุณ{fnametoken} {lnametoken}
              </h3>
              <h4 style={{ textAlign: "center", color: "black" }}>
                จำนวนที่ผิดนัดในเดือนนี้ทั้งหมด = {datablock.length} ครั้ง
              </h4>
              <h5 style={{ textAlign: "center", color: "red" }}>
                ระบบนัดหมายด้วยตนเองสามารถผิดนัดได้ไม่เกิน 3 ครั้ง/เดือน
              </h5>
            </div>
            <TableContainer component={Paper}>
              <table>
                <thead>
                  <tr className="headblock">
                    <td>
                      <h4>วันที่</h4>
                    </td>
                    <td>
                      <h4>เวลา</h4>
                    </td>
                    <td>
                      <h4>เจ้าหน้าที่</h4>
                    </td>
                  </tr>
                </thead>

                <tbody className="searchblock">
                  {datablock.map((row) => (
                    <tr key={uuidv4()}>
                      <td>{row.date}</td>
                      <td>{row.time}</td>
                      <td>{row.provider}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </Paper>
        </Box>
        <Box>
        <Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <h2>ค้นหานัดหมายผู้รับบริการ</h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <TextField
                id="fullWidth"
                label="กรอกชื่อหรือนามสกุล เพื่อค้นหานัดหมาย..."
                align="center"
                variant="outlined"
                onChange={apptesttt}
                size="small"
                style={{ marginTop: "10px", width: "90%" }}
              />
            </div>
          <TableContainer sx={{ height: "150px"}} style={{marginBottom:"10px"}}>
            <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: "60px",
                          maxHeight: "30px",
                          padding: "5px 0 5px 0",
                          backgroundColor: "#1bb3b3",
                        }}
                        // className="searchbackdate"
                      >
                        วันที่
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: "50px",
                          maxHeight: "30px",
                          padding: "5px 0 5px 0",
                          backgroundColor: "#1bb3b3",
                        }}
                        // className="searchbacktime"
                      >
                        เวลา
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxHeight: "30px",
                          maxWidth: "90px",
                          padding: "0 0 0 0",
                          backgroundColor: "#1bb3b3",
                        }}
                        // className="searchbackname"
                      >
                        ชื่อ-สกุล
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: "30px",
                          padding: "0 0 0 0",
                          backgroundColor: "#1bb3b3",
                        }}
                        // className="searchbackprovider"
                      >
                        หัตถการ
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          maxWidth: "30px",
                          padding: "5px 5px 5px 5px",
                          backgroundColor: "#1bb3b3",
                        }}
                        // className="searchbackrole"
                      >
                        เจ้าหน้าที่
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appalldata
                      .filter((e) => {
                        return (
                          e.fname.includes(appsearch) ||
                          e.lname.includes(appsearch) ||
                          e.TN.toString().includes(appsearch) ||
                          e.tel.includes(appsearch)
                        );
                      })
                      .map((item) => {
                        return (
                          <TableRow key={uuidv4()} className="searchusersrow">
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "60px",
                                padding: "5px 1px 5px 1px",
                              }}
                              // className="searchbackdate"
                            >
                              {item.date}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "40px",
                                padding: "5px 0 5px 0",
                              }}
                              // className="searchbacktime"
                            >
                              {item.time} น.
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                maxWidth: "90px",
                                maxHeight: "10px",
                                padding: "0 0 0 10px",
                              }}
                              // className="searchbackname"
                            >
                              {item.prefix}
                              {item.fname} {item.lname}
                            </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  maxWidth: "50px",
                                  padding: "5px 5px 5px 5px",
                                }}
                                // className="searchbackrole"
                              >
                                {item.service}
                              </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                maxWidth: "30px",
                                padding: "0 0 0 0",
                              }}
                              // className="searchbackprovider"
                            >
                              {item.provider}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default Search ;
