import { Paper } from "@mui/material";
import Navback from "./Navbarback";
import Ondate from "./ondate";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box} from "@mui/material";
import React,{ useEffect } from "react";
import axios from "axios";
import Footer from "../fronthome/footer/footer";
import './backsearch.css'

const Backhome = () => {
  useEffect(() => {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: 'https://testapi.thaipadi.org/authen',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
        // alert('login success')
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' )){
        window.location = '/'
      }   
      else{
        alert('ท่านไม่ได้รับสิทธิ์ในการเข้าถึงข้อมูลนี้')
        localStorage.clear();
        window.location = '/login'
      }
    })
    .catch(function (error) {
      console.log(error);
    });

    }, []);


  return (
    <div>
      <Navback />
      <CssBaseline />
      <Container>
      <Box>
        <Paper style={{ marginTop: "100px" , marginBottom:"80px" }}>
            <Ondate />
        </Paper>
      </Box>
      </Container>
      <Footer/>
    </div>
  );
};
export default Backhome;