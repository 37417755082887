import Navbar from "./Navbar/Navbar";
import "./Calendar.css";
import * as React from "react";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  Paper,
  Select,
  InputLabel,
  TextField,
  MenuItem,
  FormControl,
  Container,
  CssBaseline,
} from "@mui/material";
import axios from "axios";
import { EventAvailable, EventBusy } from "@mui/icons-material/";
import Footer from "./footer/footer";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Calendar = () => {
  const tntoken = localStorage.getItem("TN");
  const fnametoken = localStorage.getItem("fname");
  const lnametoken = localStorage.getItem("lname");
  const prefixtoken = localStorage.getItem("prefix");
  const navigate = useNavigate();
  const URL = "https://testapi.thaipadi.org";

  //date
  useEffect(() => {
    const token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: `${URL}/authen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "OK") {
          // alert('login success')
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'กรุณาเข้าสู่ระบบก่อนใช้งาน',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            navigate("/login");
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate,URL]);


  useEffect(() => {
    getblock()
  }, []);

    //ดึงข้อมูลผิดนัด
    const [datablock, setDatablock] = useState([]);
    const getblock = ()=>{
      const tntoken = localStorage.getItem("TN");
      const usersURL1 = `${URL}/blocklist/`;
      axios.get(`${usersURL1}${tntoken}`).then((res) => {
        setDatablock(res.data.length);
      });
    }
    //ดึงข้อมูลผิดนัด


  //จำนวนครั้งที่นัด

  const [mandata, setMandata] = useState([]);
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [datass, setDatass] = useState([]);
  const apl = mandata + data + datas + datass;

  const checkL = () => {
    const tntoken = localStorage.getItem("TN");
    const usersURL1 = `${URL}/manappoinment/`;
    const usersURL2 = `${URL}/appoinment/`;
    const usersURL3 = `${URL}/appoinments/`;
    const usersURL4 = `${URL}/appoinmentss/`;

    axios.get(`${usersURL1}${tntoken}`).then((res) => {
      setMandata(res.data.length);
    });
    axios.get(`${usersURL2}${tntoken}`).then((res) => {
      setData(res.data.length);
    });
    axios.get(`${usersURL3}${tntoken}`).then((res) => {
      setDatas(res.data.length);
    });
    axios.get(`${usersURL4}${tntoken}`).then((res) => {
      setDatass(res.data.length);
    });
  };
  //จำนวนครั้งที่นัด
  checkL();
  //กฟะำ

  // const [affterdate, setAffterdate] = useState(new Date());

  // new Date(new Date().setDate(new Date().getDate() + 1))

  const [startDate, setStartDate] = useState(new Date());
  const [dayname, setDayname] = useState("");

  // const redostartdate =()=>{
  //   setStartDate(startDate.setDate(startDate.getDate() + 1) )
  // }
  // const undostartdate =()=>{
  //   setStartDate(startDate.setDate(startDate.getDate() - 1) )
  // }

  useEffect(() => {
    if (startDate.getDay() === 0) {
      setDayname("วันอาทิตย์");
      return;
    } else if (startDate.getDay() === 1) {
      setDayname("วันจันทร์");
      return;
    } else if (startDate.getDay() === 2) {
      setDayname("วันอังคาร");
      return;
    } else if (startDate.getDay() === 3) {
      setDayname("วันพุธ");
      return;
    } else if (startDate.getDay() === 4) {
      setDayname("วันพฤหัส");
      return;
    } else if (startDate.getDay() === 5) {
      setDayname("วันศุกร์");
      return;
    } else if (startDate.getDay() === 6) {
      setDayname("วันเสาร์");
    }
  }, [startDate]);

  //date

  //09:00
  //one
  const [data9one, setData9one] = useState([]);
  const [data9one2, setData9one2] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [icon91, setIcon91] = useState(<EventAvailable />);
  const [status91, setStatus91] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data9one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData9one(data9one1[1]);
          setEditButton91(true);
          setIcon91(<EventBusy />);
          setStatus91("ไม่ว่าง");
          return;
        } else {
          setData9one(data9one1[0]);
          setEditButton91(false);
          setIcon91(<EventAvailable />);
          setStatus91("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData9one(data9one1[1]);
          setEditButton91(true);
          setIcon91(<EventBusy />);
          setStatus91("ไม่ว่าง");
          return;
        } else {
          setData9one(res.data[0]);
          setEditButton91(true);
          setIcon91(<EventBusy />);
          setStatus91("ไม่ว่าง");
          return;
        }
      }
    });
  }, [startDate, data9one2, URL]);

  const addmanaappoinment91 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinments`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen91(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck91();
                setOpen91(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck91 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9one2(res.data[0]);
    });
  };

  //modal

  const check91 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen91();
    }
  };
  const [open91, setOpen91] = useState(false);
  const handleOpen91 = () => setOpen91(true);
  const handleClose91 = () => setOpen91(false);

  const [service91, setService91] = useState("");

  const handleChange91 = (event) => {
    setService91(event.target.value);
  };
  //modal

  //one

  //two
  const [data9two, setData9two] = useState([]);
  const [data9two2, setData9two2] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [icon92, setIcon92] = useState(<EventAvailable />);
  const [status92, setStatus92] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data9one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData9two(data9one2[1]);
          setEditButton92(true);
          setIcon92(<EventBusy />);
          setStatus92("ไม่ว่าง");
          return;
        } else {
          setData9two(data9one2[0]);
          setEditButton92(false);
          setIcon92(<EventAvailable />);
          setStatus92("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData9two(data9one2[1]);
          setEditButton92(true);
          setIcon92(<EventBusy />);
          setStatus92("ไม่ว่าง");
          return;
        } else {
          setData9two(res.data[0]);
          setEditButton92(true);
          setIcon92(<EventBusy />);
          setStatus92("ไม่ว่าง");
          return;
        }
      }
    });
  }, [startDate,data9two2, URL]);

  const addmanaappoinment92 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen92(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck92();
                setOpen92(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck92 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9two2(res.data[0]);
    });
  };

  //modal
  const check92 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  else {
      handleOpen92();
    }
  };
  const [open92, setOpen92] = useState(false);
  const handleOpen92 = () => setOpen92(true);
  const handleClose92 = () => setOpen92(false);

  const [service92, setService92] = useState("");

  const handleChange92 = (event) => {
    setService92(event.target.value);
  };
  //modal

  //two
  //tree
  const [data9tree, setData9tree] = useState([]);
  const [data9tree2, setData9tree2] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [icon93, setIcon93] = useState(<EventAvailable />);
  const [status93, setStatus93] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data9one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData9tree(data9one3[1]);
          setEditButton93(true);
          setIcon93(<EventBusy />);
          setStatus93("ไม่ว่าง");
          return;
        } else {
          setData9tree(data9one3[0]);
          setEditButton93(false);
          setIcon93(<EventAvailable />);
          setStatus93("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData9tree(data9one3[1]);
          setEditButton93(true);
          setIcon93(<EventBusy />);
          setStatus93("ไม่ว่าง");
          return;
        }
        setData9tree(res.data[0]);
        setEditButton93(true);
        setIcon93(<EventBusy />);
        setStatus93("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data9tree2, URL]);

  const addmanaappoinment93 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentsss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen93(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck93();
                setOpen93(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck93 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9tree2(res.data[0]);
    });
  };

  //modal

  const check93 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  else {
      handleOpen93();
    }
  };
  const [open93, setOpen93] = useState(false);
  const handleOpen93 = () => setOpen93(true);
  const handleClose93 = () => setOpen93(false);

  const [service93, setService93] = useState("");

  const handleChange93 = (event) => {
    setService93(event.target.value);
  };
  //modal

  //tree

  //four

  const [data9four, setData9four] = useState([]);
  const [data9four2, setData9four2] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [icon94, setIcon94] = useState(<EventAvailable />);
  const [status94, setStatus94] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data9one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData9four(data9one4[1]);
          setEditButton94(true);
          setIcon94(<EventBusy />);
          setStatus94("ไม่ว่าง");
          return;
        } else {
          setData9four(data9one4[0]);
          setEditButton94(false);
          setIcon94(<EventAvailable />);
          setStatus94("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData9four(data9one4[1]);
          setEditButton94(true);
          setIcon94(<EventBusy />);
          setStatus94("ไม่ว่าง");
          return;
        }

        setData9four(res.data[0]);
        setEditButton94(true);
        setIcon94(<EventBusy />);
        setStatus94("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data9four2, URL]);

  const addmanaappoinment94 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/manapppoinment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen94(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck94();
                setOpen94(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck94 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData9four2(res.data[0]);
    });
  };

  //modal

  const check94 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen94();
    }
  };
  const [open94, setOpen94] = useState(false);
  const handleOpen94 = () => setOpen94(true);
  const handleClose94 = () => setOpen94(false);

  const [service94, setService94] = useState("");

  const handleChange94 = (event) => {
    setService94(event.target.value);
  };
  //modal

  //four

  //09:00

  //10:00
  //one
  const [data10one, setData10one] = useState([]);
  const [data10one2, setData10one2] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [icon101, setIcon101] = useState(<EventAvailable />);
  const [status101, setStatus101] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data10one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData10one(data10one1[1]);
          setEditButton101(true);
          setIcon101(<EventBusy />);
          setStatus101("ไม่ว่าง");
          return;
        } else {
          setData10one(data10one1[0]);
          setEditButton101(false);
          setIcon101(<EventAvailable />);
          setStatus101("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData10one(data10one1[1]);
          setEditButton101(true);
          setIcon101(<EventBusy />);
          setStatus101("ไม่ว่าง");
          return;
        }
        setData10one(res.data[0]);
        setEditButton101(true);
        setIcon101(<EventBusy />);
        setStatus101("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data10one2,URL]);

  const addmanaappoinment101 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinments`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen101(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck101();
                setOpen101(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck101 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10one2(res.data[0]);
    });
  };

  //modal
  const check101 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen101();
    }
  };
  const [open101, setOpen101] = useState(false);
  const handleOpen101 = () => setOpen101(true);
  const handleClose101 = () => setOpen101(false);

  const [service101, setService101] = useState("");

  const handleChange101 = (event) => {
    setService101(event.target.value);
  };
  //modal

  //one

  //two
  const [data10two, setData10two] = useState([]);
  const [data10two2, setData10two2] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [icon102, setIcon102] = useState(<EventAvailable />);
  const [status102, setStatus102] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData10two(data10one2[1]);
          setEditButton102(true);
          setIcon102(<EventBusy />);
          setStatus102("ไม่ว่าง");
          return;
        } else {
          setData10two(data10one2[0]);
          setEditButton102(false);
          setIcon102(<EventAvailable />);
          setStatus102("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData10two(data10one2[1]);
          setEditButton102(true);
          setIcon102(<EventBusy />);
          setStatus102("ไม่ว่าง");
          return;
        }
        setData10two(res.data[0]);
        setEditButton102(true);
        setIcon102(<EventBusy />);
        setStatus102("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data10two2, URL]);

  const addmanaappoinment102 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen102(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck102();
                setOpen102(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck102 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10two2(res.data[0]);
    });
  };

  //modal
  const check102 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  else {
      handleOpen102();
    }
  };
  const [open102, setOpen102] = useState(false);
  const handleOpen102 = () => setOpen102(true);
  const handleClose102 = () => setOpen102(false);

  const [service102, setService102] = useState("");

  const handleChange102 = (event) => {
    setService102(event.target.value);
  };
  //modal

  //two

  //tree
  const [data10tree, setData10tree] = useState([]);
  const [data10tree2, setData10tree2] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [icon103, setIcon103] = useState(<EventAvailable />);
  const [status103, setStatus103] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data10one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData10tree(data10one3[1]);
          setEditButton103(true);
          setIcon103(<EventBusy />);
          setStatus103("ไม่ว่าง");
          return;
        } else {
          setData10tree(data10one3[0]);
          setEditButton103(false);
          setIcon103(<EventAvailable />);
          setStatus103("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData10tree(data10one3[1]);
          setEditButton103(true);
          setIcon103(<EventBusy />);
          setStatus103("ไม่ว่าง");
          return;
        }
        setData10tree(res.data[0]);
        setEditButton103(true);
        setIcon103(<EventBusy />);
        setStatus103("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data10tree2,URL]);

  const addmanaappoinment103 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentsss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen103(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck103();
                setOpen103(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck103 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10tree2(res.data[0]);
    });
  };

  //modal
  const check103 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen103();
    }
  };
  const [open103, setOpen103] = useState(false);
  const handleOpen103 = () => setOpen103(true);
  const handleClose103 = () => setOpen103(false);

  const [service103, setService103] = useState("");

  const handleChange103 = (event) => {
    setService103(event.target.value);
  };
  //modal

  //tree

  //four

  const [data10four, setData10four] = useState([]);
  const [data10four2, setData10four2] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [icon104, setIcon104] = useState(<EventAvailable />);
  const [status104, setStatus104] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data10one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        time: "10:15",
        date: bbb,
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData10four(data10one4[1]);
          setEditButton104(true);
          setIcon104(<EventBusy />);
          setStatus104("ไม่ว่าง");
          return;
        } else {
          setData10four(data10one4[0]);
          setEditButton104(false);
          setIcon104(<EventAvailable />);
          setStatus104("ว่าง");
          return;
        }
      } else if (res.data.length === 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData10four(data10one4[1]);
          setEditButton104(true);
          setIcon104(<EventBusy />);
          setStatus104("ไม่ว่าง");
          return;
        }
        setData10four(res.data[0]);
        setEditButton104(true);
        setIcon104(<EventBusy />);
        setStatus104("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data10four2, URL]);

  const addmanaappoinment104 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/manapppoinment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen104(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck104();
                setOpen104(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck104 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData10four2(res.data[0]);
    });
  };

  //modal
  const check104 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen104();
    }
  };
  const [open104, setOpen104] = useState(false);
  const handleOpen104 = () => setOpen104(true);
  const handleClose104 = () => setOpen104(false);

  const [service104, setService104] = useState("");

  const handleChange104 = (event) => {
    setService104(event.target.value);
  };
  //modal

  //four

  //10:00

  //13:30
  //one
  const [data13one, setData13one] = useState([]);
  const [data13one2, setData13one2] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [icon131, setIcon131] = useState(<EventAvailable />);
  const [status131, setStatus131] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data13one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData13one(data13one1[1]);
          setEditButton131(true);
          setIcon131(<EventBusy />);
          setStatus131("ไม่ว่าง");
          return;
        } else {
          setData13one(data13one1[0]);
          setEditButton131(false);
          setIcon131(<EventAvailable />);
          setStatus131("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData13one(data13one1[1]);
          setEditButton131(true);
          setIcon131(<EventBusy />);
          setStatus131("ไม่ว่าง");
          return;
        }
        setData13one(res.data[0]);
        setEditButton131(true);
        setIcon131(<EventBusy />);
        setStatus131("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data13one2, URL]);

  const addmanaappoinment131 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });
      var config = {
        method: "post",
        url: `${URL}/apppoinments`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen131(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck131();
                setOpen131(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck131 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13one2(res.data[0]);
    });
  };

  //modal
  const check131 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen131();
    }
  };
  const [open131, setOpen131] = useState(false);
  const handleOpen131 = () => setOpen131(true);
  const handleClose131 = () => setOpen131(false);

  const [service131, setService131] = useState("");

  const handleChange131 = (event) => {
    setService131(event.target.value);
  };
  //modal

  //one

  //two
  const [data13two, setData13two] = useState([]);
  const [data13two2, setData13two2] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [icon132, setIcon132] = useState(<EventAvailable />);
  const [status132, setStatus132] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data13one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData13two(data13one2[1]);
          setEditButton132(true);
          setIcon132(<EventBusy />);
          setStatus132("ไม่ว่าง");
          return;
        } else {
          setData13two(data13one2[0]);
          setEditButton132(false);
          setIcon132(<EventAvailable />);
          setStatus132("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1")
        ) {
          setData13two(data13one2[1]);
          setEditButton132(true);
          setIcon132(<EventBusy />);
          setStatus132("ไม่ว่าง");
          return;
        }
        setData13two(res.data[0]);
        setEditButton132(true);
        setIcon132(<EventBusy />);
        setStatus132("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data13two2, URL]);
  const addmanaappoinment132 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen132(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck132();
                setOpen132(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck132 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13two2(res.data[0]);
    });
  };

  //modal
  const check132 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen132();
    }
  };
  const [open132, setOpen132] = useState(false);
  const handleOpen132 = () => setOpen132(true);
  const handleClose132 = () => setOpen132(false);

  const [service132, setService132] = useState("");

  const handleChange132 = (event) => {
    setService132(event.target.value);
  };
  //modal

  //two

  //tree
  const [data13tree, setData13tree] = useState([]);
  const [data13tree2, setData13tree2] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [icon133, setIcon133] = useState(<EventAvailable />);
  const [status133, setStatus133] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data13one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        time: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData13tree(data13one3[1]);
          setEditButton133(true);
          setIcon133(<EventBusy />);
          setStatus133("ไม่ว่าง");
          return;
        } else {
          setData13tree(data13one3[0]);
          setEditButton133(false);
          setIcon133(<EventAvailable />);
          setStatus133("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData13tree(data13one3[1]);
          setEditButton133(true);
          setIcon133(<EventBusy />);
          setStatus133("ไม่ว่าง");
          return;
        }
        setData13tree(res.data[0]);
        setEditButton133(true);
        setIcon133(<EventBusy />);
        setStatus133("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data13tree2,URL]);

  const addmanaappoinment133 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentsss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen133(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck133();
                setOpen133(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck133 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13tree2(res.data[0]);
    });
  };

  //modal
  const check133 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen133();
    }
  };
  const [open133, setOpen133] = useState(false);
  const handleOpen133 = () => setOpen133(true);
  const handleClose133 = () => setOpen133(false);

  const [service133, setService133] = useState("");

  const handleChange133 = (event) => {
    setService133(event.target.value);
  };
  //modal

  //tree

  //four

  const [data13four, setData13four] = useState([]);
  const [data13four2, setData13four2] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [icon134, setIcon134] = useState(<EventAvailable />);
  const [status134, setStatus134] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data13one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        time: "13:30",
        date: bbb,
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        time: null,
        date: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData13four(data13one4[1]);
          setEditButton134(true);
          setIcon134(<EventBusy />);
          setStatus134("ไม่ว่าง");
          return;
        } else {
          setData13four(data13one4[0]);
          setEditButton134(false);
          setIcon134(<EventAvailable />);
          setStatus134("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData13four(data13one4[1]);
          setEditButton134(true);
          setIcon134(<EventBusy />);
          setStatus134("ไม่ว่าง");
          return;
        }
        setData13four(res.data[0]);
        setEditButton134(true);
        setIcon134(<EventBusy />);
        setStatus134("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data13four2, URL]);

  const addmanaappoinment134 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/manapppoinment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen134(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck134();
                setOpen134(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck134 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData13four2(res.data[0]);
    });
  };

  //modal
  const check134 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen134();
    }
  };
  const [open134, setOpen134] = useState(false);
  const handleOpen134 = () => setOpen134(true);
  const handleClose134 = () => setOpen134(false);

  const [service134, setService134] = useState("");

  const handleChange134 = (event) => {
    setService134(event.target.value);
  };
  //modal

  //four
  //13:30

  //14:45
  //one
  const [data14one, setData14one] = useState([]);
  const [data14one2, setData14one2] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [icon141, setIcon141] = useState(<EventAvailable />);
  const [status141, setStatus141] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data14one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        prefix: null,
        time: "14:45",
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        prefix: null,
        time: null,
      },
    ];
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData14one(data14one1[1]);
          setEditButton141(true);
          setIcon141(<EventBusy />);
          setStatus141("ไม่ว่าง");
          return;
        } else {
          setData14one(data14one1[0]);
          setEditButton141(false);
          setIcon141(<EventAvailable />);
          setStatus141("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData14one(res.data[0]);
        setEditButton141(true);
        setIcon141(<EventBusy />);
        setStatus141("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data14one2, URL]);

  const addmanaappoinment141 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });
      var config = {
        method: "post",
        url: `${URL}/apppoinments`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen141(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck141();
                setOpen141(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck141 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14one2(res.data[0]);
    });
  };

  //modal
  const check141 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen141();
    }
  };
  const [open141, setOpen141] = useState(false);
  const handleOpen141 = () => setOpen141(true);
  const handleClose141 = () => setOpen141(false);

  const [service141, setService141] = useState("");

  const handleChange141 = (event) => {
    setService141(event.target.value);
  };
  //modal
  //one

  //two
  const [data14two, setData14two] = useState([]);
  const [data14two2, setData14two2] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [icon142, setIcon142] = useState(<EventAvailable />);
  const [status142, setStatus142] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data14one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        prefix: null,
        time: "14:45",
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        prefix: null,
        time: null,
      },
    ];

    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData14two(data14one2[1]);
          setEditButton142(true);
          setIcon142(<EventBusy />);
          setStatus142("ไม่ว่าง");
          return;
        } else {
          setData14two(data14one2[0]);
          setEditButton142(false);
          setIcon142(<EventAvailable />);
          setStatus142("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData14two(res.data[0]);
        setEditButton142(true);
        setIcon142(<EventBusy />);
        setStatus142("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data14two2,URL]);

  const addmanaappoinment142 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen142(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck142();
                setOpen142(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck142 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinments/12/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14two2(res.data[0]);
    });
  };

  //modal
  const check142 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen142();
    }
  };
  const [open142, setOpen142] = useState(false);
  const handleOpen142 = () => setOpen142(true);
  const handleClose142 = () => setOpen142(false);

  const [service142, setService142] = useState("");

  const handleChange142 = (event) => {
    setService142(event.target.value);
  };
  //modal

  //two
  //tree

  const [data14tree, setData14tree] = useState([]);
  const [data14tree2, setData14tree2] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [icon143, setIcon143] = useState(<EventAvailable />);
  const [status143, setStatus143] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data14one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        prefix: null,
        time: "14:45",
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        prefix: null,
        time: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData14tree(data14one3[1]);
          setEditButton143(true);
          setIcon143(<EventBusy />);
          setStatus143("ไม่ว่าง");
          return;
        } else {
          setData14tree(data14one3[0]);
          setEditButton143(false);
          setIcon143(<EventAvailable />);
          setStatus143("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData14tree(res.data[0]);
        setEditButton143(true);
        setIcon143(<EventBusy />);
        setStatus143("ไม่ว่าง");
        return;
      }
      console.log(data14one3);
    });
  }, [startDate,data14tree2, URL]);

  const addmanaappoinment143 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/apppoinmentsss`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen143(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck143();
                setOpen143(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck143 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14tree2(res.data[0]);
    });
  };

  //modal
  const check143 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen143();
    }
  };
  const [open143, setOpen143] = useState(false);
  const handleOpen143 = () => setOpen143(true);
  const handleClose143 = () => setOpen143(false);

  const [service143, setService143] = useState("");

  const handleChange143 = (event) => {
    setService143(event.target.value);
  };
  //modal

  //tree

  //four

  const [data14four, setData14four] = useState([]);
  const [data14four2, setData14four2] = useState([]);
  const [editButton144, setEditButton144] = useState();
  const [icon144, setIcon144] = useState(<EventAvailable />);
  const [status144, setStatus144] = useState("ว่าง");

  useEffect(() => {
    const roletoken = localStorage.getItem("role");
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data14one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        time: "14:45",
        date: bbb,
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        time: null,
        date: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if (
          (startDate.getDay() === 0 || startDate.getDay() === 6) &&
          (roletoken === "user" ||
            roletoken === "UC" ||
            roletoken === "A7" ||
            roletoken === "A1" ||
            roletoken === "A2" ||
            roletoken === "admin")
        ) {
          setData14four(data14one4[1]);
          setEditButton144(true);
          setIcon144(<EventBusy />);
          setStatus144("ไม่ว่าง");
          return;
        } else {
          setData14four(data14one4[0]);
          setEditButton144(false);
          setIcon144(<EventAvailable />);
          setStatus144("ว่าง");
          return;
        }
      } else if (res.data.length === 1) {
        setData14four(res.data[0]);
        setEditButton144(true);
        setIcon144(<EventBusy />);
        setStatus144("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,data14four2, URL]);

  const addmanaappoinment144 = (date, ttttime, provider, service) => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      var data = JSON.stringify({
        TN: tntoken,
        date: date,
        time: ttttime,
        service: service,
        provider: provider,
      });

      var config = {
        method: "post",
        url: `${URL}/manapppoinment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (res.data.length >= 1) {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถทำการนัดหมายได้ เนื่องจากมีคนอื่นนัดหมายช่วงเวลานี้แล้ว",
          confirmButtonColor: "#d33",
          confirmButtonText: "ปิด",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpen144(false);
          }
        });
        return;
      } else if (!service) {
        Swal.fire({
          icon: "warning",
          title: "มีข้อผิดพลาด",
          text: "กรุณาเลือกหัตถการที่ต้องการ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        axios(config)
          .then(function (response) {
            if (response.data["status"] === "OK") {
              Swal.fire({
                icon: "success",
                title: "นัดหมายเรียบร้อย",
                text: "กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง!",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                recheck144();
                setOpen144(false);
              });
            } else {
              alert(JSON.stringify(response.data));
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    });
  };

  const recheck144 = () => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      setData14four2(res.data[0]);
    });
  };

  //modal
  const check144 = () => {
    if (datablock >= 3) {
      Swal.fire({
        title: "ท่านผิดนัดหมายเกินกำหนด",
        html: "<div>ระบบให้ผิดนัดหมายได้ไม่เกิน 3 ครั้ง/เดือน</div>หากต้องการนัดหมายกรุณาติดต่อเจ้าหน้าที่โดยตรง",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    }  
    else if (apl >= 3) {
      Swal.fire({
        title: "ท่านนัดหมายเกินกำหนด",
        html: "<div>ระบบให้นัดหมายล้วงหน้าได้ไม่เกิน 3 ครั้ง</div>หากต้องการนัดหมายกรุณายกเลิกนัดหมายอื่นของท่านก่อน",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ปิด",
        confirmButtonText: "ตรวจสอบนัด",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/search");
        }
      });
      return
    } else {
      handleOpen144();
    }
  };
  const [open144, setOpen144] = useState(false);
  const handleOpen144 = () => setOpen144(true);
  const handleClose144 = () => setOpen144(false);

  const [service144, setService144] = useState("");

  const handleChange144 = (event) => {
    setService144(event.target.value);
  };

  //four

  //14:45
  return (
    <div>
      <Navbar />
      <CssBaseline />
      <Container>
        <Box>
          <Paper style={{ marginBottom: "80px" }}>
            <div style={{ textAlign: "center" }}>
              <h2 style={{ margin: "100px 10px 10px 10px" }}>
                <span style={{ marginRight: "10px" }}></span>
                <DatePicker
                  onChange={(Date) => setStartDate(Date)}
                  value={startDate}
                  format="dd-MM-y"
                  locale="th-TH"
                  minDate={new Date()}
                />
                <span style={{ marginLeft: "10px", fontSize: "small" }}>
                  {dayname}
                </span>
              </h2>
            </div>
            <div className="testtest">
              <table>
                <tbody>
                  <tr className="head">
                    <td className="headtime">เวลา</td>
                    <td className="headname">ชื่อ-สกุล</td>
                    <td className="headservice">หัตถการ</td>
                    <td className="headprovider">ผู้ให้บริการ</td>
                    <td className="headstatus">สถานะ</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9one.prefix}
                      {data9one.fname} {data9one.lname}
                    </td>
                    <td className="bodyservice">{data9one.service}</td>
                    <td className="bodytime">{data9one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check91}
                        startIcon={icon91}
                        disabled={editButton91}
                        className="btnstatus"
                      >
                        {status91}
                      </Button>
                      <Modal
                        keepMounted
                        open={open91}
                        onClose={handleClose91}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data9one.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data9one.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data9one.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service91}
                                      onChange={handleChange91}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment91(
                                        data9one.date,
                                        data9one.time,
                                        data9one.provider,
                                        service91
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose91}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9two.prefix}
                      {data9two.fname} {data9two.lname}
                    </td>
                    <td className="bodyservice">{data9two.service}</td>
                    <td className="bodytime">{data9two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check92}
                        startIcon={icon92}
                        disabled={editButton92}
                        className="btnstatus"
                      >
                        {status92}
                      </Button>
                      <Modal
                        keepMounted
                        open={open92}
                        onClose={handleClose92}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data9two.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data9two.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data9two.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service92}
                                      onChange={handleChange92}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment92(
                                        data9two.date,
                                        data9two.time,
                                        data9two.provider,
                                        service92
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose92}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9tree.prefix}
                      {data9tree.fname} {data9tree.lname}
                    </td>
                    <td className="bodyservice">{data9tree.service}</td>
                    <td className="bodytime">{data9tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check93}
                        startIcon={icon93}
                        disabled={editButton93}
                        className="btnstatus"
                      >
                        {status93}
                      </Button>
                      <Modal
                        keepMounted
                        open={open93}
                        onClose={handleClose93}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data9tree.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data9tree.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data9tree.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service93}
                                      onChange={handleChange93}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment93(
                                        data9tree.date,
                                        data9tree.time,
                                        data9tree.provider,
                                        service93
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose93}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9four.prefix}
                      {data9four.fname} {data9four.lname}
                    </td>
                    <td className="bodyservice">{data9four.service}</td>
                    <td className="bodytime">{data9four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={check94}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={icon94}
                        disabled={editButton94}
                        className="btnstatus"
                      >
                        {status94}
                      </Button>
                      <Modal
                        keepMounted
                        open={open94}
                        onClose={handleClose94}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data9four.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data9four.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data9four.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service94}
                                      onChange={handleChange94}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment94(
                                        data9four.date,
                                        data9four.time,
                                        data9four.provider,
                                        service94
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose94}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10one.prefix}
                      {data10one.fname} {data10one.lname}
                    </td>
                    <td className="bodyservice">{data10one.service}</td>
                    <td className="bodytime">{data10one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={icon101}
                        onClick={check101}
                        disabled={editButton101}
                        className="btnstatus"
                      >
                        {status101}
                      </Button>
                      <Modal
                        keepMounted
                        open={open101}
                        onClose={handleClose101}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data10one.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data10one.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data10one.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service101}
                                      onChange={handleChange101}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment101(
                                        data10one.date,
                                        data10one.time,
                                        data10one.provider,
                                        service101
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose101}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10two.prefix}
                      {data10two.fname} {data10two.lname}
                    </td>
                    <td className="bodyservice">{data10two.service}</td>
                    <td className="bodytime">{data10two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check102}
                        startIcon={icon102}
                        disabled={editButton102}
                        className="btnstatus"
                      >
                        {status102}
                      </Button>
                      <Modal
                        keepMounted
                        open={open102}
                        onClose={handleClose102}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data10two.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data10two.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data10two.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service102}
                                      onChange={handleChange102}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment102(
                                        data10two.date,
                                        data10two.time,
                                        data10two.provider,
                                        service102
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose102}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10tree.prefix}
                      {data10tree.fname} {data10tree.lname}
                    </td>
                    <td className="bodyservice">{data10tree.service}</td>
                    <td className="bodytime">{data10tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={check103}
                        size="small"
                        startIcon={icon103}
                        disabled={editButton103}
                        className="btnstatus"
                      >
                        {status103}
                      </Button>

                      <Modal
                        keepMounted
                        open={open103}
                        onClose={handleClose103}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data10tree.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data10tree.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data10tree.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service103}
                                      onChange={handleChange103}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment103(
                                        data10tree.date,
                                        data10tree.time,
                                        data10tree.provider,
                                        service103
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose103}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10four.prefix}
                      {data10four.fname} {data10four.lname}
                    </td>
                    <td className="bodyservice">{data10four.service}</td>
                    <td className="bodytime">{data10four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={check104}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={icon104}
                        disabled={editButton104}
                        className="btnstatus"
                      >
                        {status104}
                      </Button>
                      <Modal
                        keepMounted
                        open={open104}
                        onClose={handleClose104}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data10four.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data10four.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data10four.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service104}
                                      onChange={handleChange104}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment104(
                                        data10four.date,
                                        data10four.time,
                                        data10four.provider,
                                        service104
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose104}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13one.prefix}
                      {data13one.fname} {data13one.lname}
                    </td>
                    <td className="bodyservice">{data13one.service}</td>
                    <td className="bodytime">{data13one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check131}
                        startIcon={icon131}
                        disabled={editButton131}
                        className="btnstatus"
                      >
                        {status131}
                      </Button>
                      <Modal
                        keepMounted
                        open={open131}
                        onClose={handleClose131}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data13one.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data13one.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data13one.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service131}
                                      onChange={handleChange131}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment131(
                                        data13one.date,
                                        data13one.time,
                                        data13one.provider,
                                        service131
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose131}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13two.prefix}
                      {data13two.fname} {data13two.lname}
                    </td>
                    <td className="bodyservice">{data13two.service}</td>
                    <td className="bodytime">{data13two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={check132}
                        size="small"
                        startIcon={icon132}
                        disabled={editButton132}
                        className="btnstatus"
                      >
                        {status132}
                      </Button>
                      <Modal
                        keepMounted
                        open={open132}
                        onClose={handleClose132}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data13two.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data13two.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data13two.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service132}
                                      onChange={handleChange132}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment132(
                                        data13two.date,
                                        data13two.time,
                                        data13two.provider,
                                        service132
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose132}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13tree.prefix}
                      {data13tree.fname} {data13tree.lname}
                    </td>
                    <td className="bodyservice">{data13tree.service}</td>
                    <td className="bodytime">{data13tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={check133}
                        size="small"
                        startIcon={icon133}
                        disabled={editButton133}
                        className="btnstatus"
                      >
                        {status133}
                      </Button>
                      <Modal
                        keepMounted
                        open={open133}
                        onClose={handleClose133}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data13tree.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data13tree.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data13tree.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service133}
                                      onChange={handleChange133}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment133(
                                        data13tree.date,
                                        data13tree.time,
                                        data13tree.provider,
                                        service133
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose133}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13four.prefix}
                      {data13four.fname} {data13four.lname}
                    </td>
                    <td className="bodyservice">{data13four.service}</td>
                    <td className="bodytime">{data13four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={check134}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={icon134}
                        disabled={editButton134}
                        className="btnstatus"
                      >
                        {status134}
                      </Button>
                      <Modal
                        keepMounted
                        open={open134}
                        onClose={handleClose134}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data13four.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data13four.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data13four.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service134}
                                      onChange={handleChange134}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment134(
                                        data13four.date,
                                        data13four.time,
                                        data13four.provider,
                                        service134
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose134}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14one.prefix}
                      {data14one.fname} {data14one.lname}
                    </td>
                    <td className="bodyservice">{data14one.service}</td>
                    <td className="bodytime">{data14one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check141}
                        startIcon={icon141}
                        disabled={editButton141}
                        className="btnstatus"
                      >
                        {status141}
                      </Button>
                      <Modal
                        keepMounted
                        open={open141}
                        onClose={handleClose141}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data14one.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data14one.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data14one.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service141}
                                      onChange={handleChange141}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment141(
                                        data14one.date,
                                        data14one.time,
                                        data14one.provider,
                                        service141
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose142}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14two.prefix}
                      {data14two.fname} {data14two.lname}
                    </td>
                    <td className="bodyservice">{data14two.service}</td>
                    <td className="bodytime">{data14two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={check142}
                        startIcon={icon142}
                        disabled={editButton142}
                        className="btnstatus"
                      >
                        {status142}
                      </Button>
                      <Modal
                        keepMounted
                        open={open142}
                        onClose={handleClose142}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data14two.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data14two.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data14two.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service142}
                                      onChange={handleChange142}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment142(
                                        data14two.date,
                                        data14two.time,
                                        data14two.provider,
                                        service142
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose142}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14tree.prefix}
                      {data14tree.fname} {data14tree.lname}
                    </td>
                    <td className="bodyservice">{data14tree.service}</td>
                    <td className="bodytime">{data14tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={check143}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={icon143}
                        disabled={editButton143}
                        className="btnstatus"
                      >
                        {status143}
                      </Button>
                      <Modal
                        keepMounted
                        open={open143}
                        onClose={handleClose143}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data14tree.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data14tree.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data14tree.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service143}
                                      onChange={handleChange143}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment143(
                                        data14tree.date,
                                        data14tree.time,
                                        data14tree.provider,
                                        service143
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose143}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14four.prefix}
                      {data14four.fname} {data14four.lname}
                    </td>
                    <td className="bodyservice">{data14four.service}</td>
                    <td className="bodytime">{data14four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={check144}
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={icon144}
                        disabled={editButton144}
                        className="btnstatus"
                      >
                        {status144}
                      </Button>
                      <Modal
                        keepMounted
                        open={open144}
                        onClose={handleClose144}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Paper>
                          <Box sx={style}>
                            <Typography
                              id="keep-mounted-modal-title"
                              style={{ textAlign: "center", marginBottom: 16 }}
                              variant="h6"
                              component="h2"
                            >
                              กรุณาตรวจสอบความถูกต้อง
                            </Typography>
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    label="ชื่อ-สกุล"
                                    id="outlined-name"
                                    value={`${prefixtoken}${fnametoken} ${lnametoken}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="TN"
                                    id="outlined-name"
                                    value={tntoken}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="วันที่"
                                    id="outlined-name"
                                    value={`${data14four.date}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="เวลา"
                                    id="outlined-name"
                                    value={`${data14four.time}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    label="ผู้ให้บริการ"
                                    id="outlined-name"
                                    value={`${data14four.provider}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      กรุณาเลือกหัตถการที่ต้องการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service144}
                                      onChange={handleChange144}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      return addmanaappoinment144(
                                        data14four.date,
                                        data14four.time,
                                        data14four.provider,
                                        service144
                                      );
                                    }}
                                  >
                                    ยืนยัน
                                  </Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose144}
                                  >
                                    ยกเลิก
                                  </Button>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Paper>
                      </Modal>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default Calendar;
