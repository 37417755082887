import axios from "axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Navbar from "./Navbar/Navbar";


const Aboutme =()=>{
    const URL = "https://testapi.thaipadi.org"
    useEffect(() => {
        const token = localStorage.getItem("token");
    
        var config = {
          method: "post",
          url: `${URL}/authen`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
    
        axios(config)
          .then(function (response) {
            if (response.data.status === "OK") {
              // alert('login success')
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'กรุณาเข้าสู่ระบบก่อนใช้งาน',
                showConfirmButton: false,
                timer: 3000
              }).then((result)=>{
                localStorage.clear();
                window.location = "/login";
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }, [URL]);
    return(
        <div>
            <Navbar/>
            Arofat_sabuding
        </div>
    )
}
export default Aboutme;
