import { Routes , Route } from 'react-router-dom';
import './App.css';
import Backhome from './backhome/Backhome';
import Home from './fronthome/Home';
import Aboutme from './fronthome/Aboutme';
import Aboutlogin from './fronthome/aboutlogin';
import Cancelback from './backhome/cancelback';
import Block from './backhome/Block';
import Users from './backhome/Users';
import About from './fronthome/About';
import Calendar from './fronthome/Calendar';
import Login from './fronthome/Login';
import Reviews from './fronthome/Reviews';
import Signup from './fronthome/Signup';
import Search from './fronthome/Search';
import Usercreate from './backhome/Usercreate';
import Useredit from './backhome/Useredit';
import Cpass from './fronthome/cpass';
import Searchusers from './backhome/searchusers';
import Tomorowday from './backhome/nextday';
import Calendarusers from './fronthome/Calendarusers';
import Searchline from './fronthome/Searchline';
import Calendarline from './fronthome/Calendarline';
import Calendarusersline from './fronthome/Calendarusersline';
import Cancelappline from './fronthome/Cancelappline';
import Loginline from './fronthome/Loginline';
import Homeline from './fronthome/Homeline';
import Searchloginline from './fronthome/Searchloginline';
import Cancelloginline from './fronthome/cancelloginline';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/homeline" element={<Homeline />}></Route>
      <Route path="/backhome" element={<Backhome />}></Route>
      <Route path="/aboutme" element={<Aboutme />}></Route>
      <Route path="/cancelback" element={<Cancelback />}></Route>
      <Route path="/block" element={<Block />}></Route>
      <Route path="/users" element={<Users />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/aboutlogin" element={<Aboutlogin />}></Route>
      <Route path="/calendar" element={<Calendar />}></Route>
      <Route path="/calendarline" element={<Calendarline/>}></Route>
      <Route path="/calendarusersline" element={<Calendarusersline/>}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/loginline" element={<Loginline />}></Route>
      <Route path="/reviews" element={<Reviews />}></Route>
      <Route path="/signup" element={<Signup />}></Route>
      <Route path="/search" element={<Search />}></Route>
      <Route path="/searchline" element={<Searchline />}></Route>
      <Route path="/searchloginline" element={<Searchloginline />}></Route>
      <Route path="/cancelappline" element={<Cancelappline />}></Route>
      <Route path="/cancelloginline" element={<Cancelloginline />}></Route>
      <Route path="/usercreate" element={<Usercreate />}></Route>
      <Route path="/useredit/:TN" element={<Useredit />}></Route>
      <Route path="/useredit" element={<Users />}></Route>
      <Route path="/cpass" element={<Cpass />}></Route>
      <Route path="/searchusers" element={<Searchusers />}></Route>
      <Route path="/nextdays" element={<Tomorowday />}></Route>
      <Route path="/searchapp" element={<Searchline />}></Route>
      <Route path="/calendarusers" element={<Calendarusers />}></Route>
    </Routes>
  )
}
export default App;
