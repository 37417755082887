import "./Calendar.css";
import * as React from "react";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {
  Button,
  Box,
  Paper,
  Container,
  CssBaseline
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";





const Calendarusersline = () => {
  const navigate = useNavigate();
  const URL = "https://testapi.thaipadi.org"
  const [startDate, setStartDate] = useState(new Date());


  const [dayname,setDayname] = useState('')

  
  

  useEffect(()=>{
      if (startDate.getDay()===0){
        setDayname('วันอาทิตย์')
        return
      }
      else if (startDate.getDay()===1){
        setDayname('วันจันทร์')
        return
      }
      else if (startDate.getDay()===2){
        setDayname('วันอังคาร')
        return
      }
      else if (startDate.getDay()===3){
        setDayname('วันพุธ')
        return
      }
      else if (startDate.getDay()===4){
        setDayname('วันพฤหัส')
        return
      }
      else if (startDate.getDay()===5){
        setDayname('วันศุกร์')
        return
      }
      else if (startDate.getDay()===6){
        setDayname('วันเสาร์')
      }
  },[startDate]) 
  
  //date

  //09:00
  //one
  const [data9one, setData9one] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [status91, setStatus91] = useState("ว่าง");


  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data9one1 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง" , date: bbb , time:"09:00" , prefix:null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },{ fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null }
    ];

    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData9one(data9one1[2])
          setEditButton91(false)
          setStatus91("ว่าง");
          return;
        }
        setData9one(data9one1[0])
        setEditButton91(false)
        setStatus91("ว่าง");
        return;

      } else if (res.data.length >= 1) {
        setData9one(res.data[0]);
        setEditButton91(true);
        setStatus91("ไม่ว่าง");
        return;
        }
      }
    );
  }, [startDate,URL]);

  //one


  //two
  const [data9two, setData9two] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [status92, setStatus92] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data9one2 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },{ fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null }
    ];

    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData9two(data9one2[2])
          setEditButton92(false)
          setStatus92("ว่าง");
          return;
        }
        setData9two(data9one2[0])
        setEditButton92(false)
        setStatus92("ว่าง");
        return;
      } 
      else if (res.data.length >= 1) {
        setData9two(res.data[0]);
        setEditButton92(true);
        setStatus92("ไม่ว่าง");
        return;
          }
      }
    );
  }, [startDate,URL]);


  //two
  //tree
  const [data9tree, setData9tree] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [status93, setStatus93] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data9one3 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00",prefix:null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData9tree(data9one3[1]);
          setEditButton93(true);
          setStatus93("ไม่ว่าง");
          return
        }else{
          setData9tree(data9one3[0])
          setEditButton93(false)
          setStatus93("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData9tree(res.data[0]);
        setEditButton93(true);
        setStatus93("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);

  //tree

  //four

  const [data9four, setData9four] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [status94, setStatus94] = useState("ว่าง");

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

      const data9one4 = [
        {
          fname: "ว่าง",
          lname: null,
          service: "ว่าง",
          provider: "ชาย",
          date: bbb,
          time: "09:00",
          prefix: null,
        },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
      ];

    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData9four(data9one4[1]);
          setEditButton94(true);
          setStatus94("ไม่ว่าง");
          return
        }else{
          setData9four(data9one4[0])
          setEditButton94(false)
          setStatus94("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData9four(res.data[0]);
        setEditButton94(true);
        setStatus94("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //four

  //09:00

  //10:00
  //one
  const [data10one, setData10one] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [status101, setStatus101] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data10one1 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง", date:bbb , time:"10:15", prefix:null},{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },{ fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null }
    ];
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData10one(data10one1[2])
          setEditButton101(false)
          setStatus101("ว่าง");
          return;
        }
        setData10one(data10one1[0])
        setEditButton101(false)
        setStatus101("ว่าง");
        return;
      } else if (res.data.length >= 1) { 
        setData10one(res.data[0]);
        setEditButton101(true);
        setStatus101("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //one

  //two
  const [data10two, setData10two] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [status102, setStatus102] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();
    const data10one2 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง" , date:bbb , time:"10:15",prefix:null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },{ fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null }
    ];
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData10two(data10one2[2])
          setEditButton102(false)
          setStatus102("ว่าง");
          return;
        }
        setData10two(data10one2[0])
        setEditButton102(false)
        setStatus102("ว่าง");
        return;
      } else if (res.data.length >= 1) {
        setData10two(res.data[0]);
        setEditButton102(true);
        setStatus102("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);

  
  
  //two



  //tree
  const [data10tree, setData10tree] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [status103, setStatus103] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data10one3 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง" , date: bbb , time:"10:15" , prefix:null},{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];

    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData10tree(data10one3[1]);
          setEditButton103(true);
          setStatus103("ไม่ว่าง");
          return
        }else{
          setData10tree(data10one3[0])
          setEditButton103(false)
          setStatus103("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData10tree(res.data[0]);
        setEditButton103(true);
        setStatus103("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //tree


  //four

  const [data10four, setData10four] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [status104, setStatus104] = useState("ว่าง");

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data10one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        time: "10:15",
        date: bbb,
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData10four(data10one4[1]);
          setEditButton104(true);
          setStatus104("ไม่ว่าง");
          return
        }else{
          setData10four(data10one4[0])
          setEditButton104(false)
          setStatus104("ว่าง");
          return;
        }
      } else if (res.data.length === 1) {
        setData10four(res.data[0]);
        setEditButton104(true);
        setStatus104("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //four

  //10:00

  //13:30
  //one
  const [data13one, setData13one] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [status131, setStatus131] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data13one1 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง" , date: bbb ,time:"13:30", prefix:null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },{ fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null }
    ];

    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData13one(data13one1[2])
          setEditButton131(false)
          setStatus131("ว่าง");
          return;
        }
        setData13one(data13one1[0])
        setEditButton131(false)
        setStatus131("ว่าง");
        return;
      } else if (res.data.length >= 1) {
        setData13one(res.data[0]);
        setEditButton131(true);
        setStatus131("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //one

  //two
  const [data13two, setData13two] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [status132, setStatus132] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data13one2 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง" , date: bbb , time:"13:30" ,prefix:null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },{ fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)", lname: null, service: "ว่าง", provider: "หญิง", date: bbb , time:"09:00" , prefix: null }
    ];
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData13two(data13one2[2])
          setEditButton132(false)
          setStatus132("ว่าง");
          return;
        }
        setData13two(data13one2[0])
        setEditButton132(false)
        setStatus132("ว่าง");
        return;

      } else if (res.data.length >= 1) {
        setData13two(res.data[0]);
        setEditButton132(true);
        setStatus132("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);
  //two



  //tree
  const [data13tree, setData13tree] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [status133, setStatus133] = useState("ว่าง");

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data13one3 = [
      { fname: "ว่าง", lname: null, service: "ว่าง", provider: "หญิง", date:bbb ,time:"13:30", prefix:null },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];

    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData13tree(data13one3[1]);
          setEditButton133(true);
          setStatus133("ไม่ว่าง");
          return
        }else{
          setData13tree(data13one3[0])
          setEditButton133(false)
          setStatus133("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData13tree(res.data[0]);
        setEditButton133(true);
        setStatus133("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //tree

  //four

  const [data13four, setData13four] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [status134, setStatus134] = useState("ว่าง");

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data13one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        time: "13:30",
        date: bbb,
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        time: null,
        date: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData13four(data13one4[1]);
          setEditButton134(true);
          setStatus134("ไม่ว่าง");
          return
        }else{
          setData13four(data13one4[0])
          setEditButton134(false)
          setStatus134("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData13four(res.data[0]);
        setEditButton134(true);
        setStatus134("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  //four
  //13:30

  //14:45
  //one
  const [data14one, setData14one] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [status141, setStatus141] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data14one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        prefix: null,
        time: "14:45"
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        prefix: null,
        time: null
      },
    ];
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6)){
        setData14one(data14one1[1]);
        setEditButton141(true);
        setStatus141("ไม่ว่าง");
        return
      }else{
        setData14one(data14one1[0])
        setEditButton141(false)
        setStatus141("ว่าง");
        return;
      }
      } else if (res.data.length >= 1) {
        setData14one(res.data[0]);
        setEditButton141(true);
        setStatus141("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);

  //one

  //two
  const [data14two, setData14two] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [status142, setStatus142] = useState("ว่าง");

  useEffect(() => {
    const bbb =
    startDate.getDate() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getFullYear();

    const data14one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        prefix: null,
        time: "14:45",
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        prefix: null,
        time: null,
      },
    ];

    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData14two(data14one2[1]);
          setEditButton142(true);
          setStatus142("ไม่ว่าง");
          return
        }else{
          setData14two(data14one2[0])
          setEditButton142(false)
          setStatus142("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData14two(res.data[0]);
        setEditButton142(true);
        setStatus142("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);

  //two
  //tree

  const [data14tree, setData14tree] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [status143, setStatus143] = useState("ว่าง");

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data14one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        prefix: null,
        time: "14:45",
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        date: null,
        prefix: null,
        time: null,
      },
    ];
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData14tree(data14one3[1]);
          setEditButton143(true);
          setStatus143("ไม่ว่าง");
          return
        }else{
          setData14tree(data14one3[0])
          setEditButton143(false)
          setStatus143("ว่าง");
          return;
        }
      } else if (res.data.length >= 1) {
        setData14tree(res.data[0]);
        setEditButton143(true);
        setStatus143("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);



  //tree

  //four

  const [data14four, setData14four] = useState([]);
  const [editButton144, setEditButton144] = useState();
  const [status144, setStatus144] = useState("ว่าง");

  


  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();

    const data14one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        time: "14:45",
        date: bbb,
        prefix: null,
      },
      {
        fname: null,
        lname: null,
        service: null,
        provider: null,
        time: null,
        date: null,
        prefix: null,
      },
    ];

    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if(startDate.getDay()===0 || startDate.getDay()===6){
          setData14four(data14one4[1]);
          setEditButton144(true);
          setStatus144("ไม่ว่าง");
          return
        }else{
          setData14four(data14one4[0])
          setEditButton144(false)
          setStatus144("ว่าง");
          return;
        }
      } else if (res.data.length === 1) {
        setData14four(res.data[0]);
        setEditButton144(true);
        setStatus144("ไม่ว่าง");
        return;
      }
    });
  }, [startDate,URL]);


  const handleOpen = () =>{
    Swal.fire({
      icon: 'warning',
      html: "<div><h2><u>กรุณาเข้าสู่ระบบ</u><div>ก่อนใช้บริการ</div></h2></div><div><h4>หากยังไม่ลงทะเบียนกรุณาติดต่อเจ้าหน้าที่</h4></div>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ปิด",
      confirmButtonText: "เข้าสู่ระบบ",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/loginline");
      }
    });
  }
  //four

  //14:45
  return (
    <div className="aboutpages" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <CssBaseline />
      <Container>
        <Box>
          <Paper style={{ marginBottom: "10px",marginTop:'10px',paddingTop:'10px',border:'1px solid #e1932c'}}>
            <div style={{ textAlign: "center" , marginTop:'10px' }}>
              <h2 style={{ margin: "10px 10px 10px 10px" }}>
                <span style={{ marginRight: "10px" }}></span>
                <DatePicker
                  onChange={(Date) => setStartDate(Date)}
                  value={startDate}
                  format="dd-MM-y"
                  locale="th-TH"
                  minDate={new Date()}
                />
                <span style={{ marginLeft: "10px", fontSize: "small" }}>
                  {dayname}
                </span>
              </h2>
            </div>
            <div className="testtest">
              <table style={{margin:'10px'}}>
                <tbody>
                  <tr className="head" style={{fontSize:"10px"}}>
                    <td className="headtime">เวลา</td>
                    <td className="headname">ชื่อ-สกุล</td>
                    <td className="headservice">หัตถการ</td>
                    <td className="headprovider">ผู้ให้บริการ</td>
                    <td className="headstatus">สถานะ</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9one.prefix}
                      {data9one.fname} {data9one.lname}
                    </td>
                    <td className="bodyservice">{data9one.service}</td>
                    <td className="bodytime">{data9one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton91}
                        className="btnstatus"
                      >
                        {status91}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9two.prefix}
                      {data9two.fname} {data9two.lname}
                    </td>
                    <td className="bodyservice">{data9two.service}</td>
                    <td className="bodytime">{data9two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton92}
                        className="btnstatus"
                      >
                        {status92}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9tree.prefix}
                      {data9tree.fname} {data9tree.lname}
                    </td>
                    <td className="bodyservice">{data9tree.service}</td>
                    <td className="bodytime">{data9tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton93}
                        className="btnstatus"
                      >
                        {status93}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">09:00 น.</td>
                    <td className="bodyname">
                      {data9four.prefix}
                      {data9four.fname} {data9four.lname}
                    </td>
                    <td className="bodyservice">{data9four.service}</td>
                    <td className="bodytime">{data9four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={editButton94}
                        className="btnstatus"
                      >
                        {status94}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10one.prefix}
                      {data10one.fname} {data10one.lname}
                    </td>
                    <td className="bodyservice">{data10one.service}</td>
                    <td className="bodytime">{data10one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton101}
                        className="btnstatus"
                      >
                        {status101}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10two.prefix}
                      {data10two.fname} {data10two.lname}
                    </td>
                    <td className="bodyservice">{data10two.service}</td>
                    <td className="bodytime">{data10two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton102}
                        className="btnstatus"
                      >
                        {status102}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10tree.prefix}
                      {data10tree.fname} {data10tree.lname}
                    </td>
                    <td className="bodyservice">{data10tree.service}</td>
                    <td className="bodytime">{data10tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleOpen}
                        size="small"
                        disabled={editButton103}
                        className="btnstatus"
                      >
                        {status103}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">10:15 น.</td>
                    <td className="bodyname">
                      {data10four.prefix}
                      {data10four.fname} {data10four.lname}
                    </td>
                    <td className="bodyservice">{data10four.service}</td>
                    <td className="bodytime">{data10four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={editButton104}
                        className="btnstatus"
                      >
                        {status104}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13one.prefix}
                      {data13one.fname} {data13one.lname}
                    </td>
                    <td className="bodyservice">{data13one.service}</td>
                    <td className="bodytime">{data13one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton131}
                        className="btnstatus"
                      >
                        {status131}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13two.prefix}
                      {data13two.fname} {data13two.lname}
                    </td>
                    <td className="bodyservice">{data13two.service}</td>
                    <td className="bodytime">{data13two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleOpen}
                        size="small"
                        disabled={editButton132}
                        className="btnstatus"
                      >
                        {status132}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13tree.prefix}
                      {data13tree.fname} {data13tree.lname}
                    </td>
                    <td className="bodyservice">{data13tree.service}</td>
                    <td className="bodytime">{data13tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleOpen}
                        size="small"
                        disabled={editButton133}
                        className="btnstatus"
                      >
                        {status133}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">13:30 น.</td>
                    <td className="bodyname">
                      {data13four.prefix}
                      {data13four.fname} {data13four.lname}
                    </td>
                    <td className="bodyservice">{data13four.service}</td>
                    <td className="bodytime">{data13four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={editButton134}
                        className="btnstatus"
                      >
                        {status134}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14one.prefix}
                      {data14one.fname} {data14one.lname}
                    </td>
                    <td className="bodyservice">{data14one.service}</td>
                    <td className="bodytime">{data14one.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton141}
                        className="btnstatus"
                      >
                        {status141}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14two.prefix}
                      {data14two.fname} {data14two.lname}
                    </td>
                    <td className="bodyservice">{data14two.service}</td>
                    <td className="bodytime">{data14two.provider}</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={handleOpen}
                        disabled={editButton142}
                        className="btnstatus"
                      >
                        {status142}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14tree.prefix}
                      {data14tree.fname} {data14tree.lname}
                    </td>
                    <td className="bodyservice">{data14tree.service}</td>
                    <td className="bodytime">{data14tree.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={editButton143}
                        className="btnstatus"
                      >
                        {status143}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">14:45 น.</td>
                    <td className="bodyname">
                      {data14four.prefix}
                      {data14four.fname} {data14four.lname}
                    </td>
                    <td className="bodyservice">{data14four.service}</td>
                    <td className="bodytime">{data14four.provider}</td>
                    <td className="bodystatus">
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={editButton144}
                        className="btnstatus"
                      >
                        {status144}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};
export default Calendarusersline;


