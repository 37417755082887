import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import DatePicker from "react-date-picker";
import { Button } from "@mui/material";
import {
  CancelOutlined,
  EventAvailable,
  EventBusy,
  Send,
} from "@mui/icons-material/";
import "./ondate.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import {
  Container,
  TextField,
  Grid,
  CssBaseline,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import Swal from "sweetalert2";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1
};

const Newdatessss = () => {
  const URL = 'https://testapi.thaipadi.org'
  useEffect(() => {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: `${URL}/authen`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
        // alert('login success')
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' )){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้',
          showConfirmButton: false,
          timer: 3000
        }).then((result)=>{
          localStorage.clear();
          window.location.href = "/";
        })
      }   
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: 'ท่านไม่ได้รับสิทธิ์ในการเข้าถึงส่วนนี้',
          showConfirmButton: false,
          timer: 3000
        }).then((result)=>{
          localStorage.clear();
          window.location.href = "/login";
        })
      }
    })
  .catch(function (error) {
    console.log(error);
  });

  }, [URL]);


  //date
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 4));

  const [dayname,setDayname] = useState('')
  
  const [startDate, setStartDate] = useState(tomorrow);
  
  useEffect(()=>{
      if (startDate.getDay()===0){
        setDayname('วันอาทิตย์')
        return
      }
      else if (startDate.getDay()===1){
        setDayname('วันจันทร์')
        return
      }
      else if (startDate.getDay()===2){
        setDayname('วันอังคาร')
        return
      }
      else if (startDate.getDay()===3){
        setDayname('วันพุธ')
        return
      }
      else if (startDate.getDay()===4){
        setDayname('วันพฤหัส')
        return
      }
      else if (startDate.getDay()===5){
        setDayname('วันศุกร์')
        return
      }
      else if (startDate.getDay()===6){
        setDayname('วันเสาร์')
      }
  },[startDate])

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${URL}/users`).then((res) => {
      setData(res.data);
    });
  }, [URL]);

  const [search, setSearch] = useState("");
  const testtt = (e) => {
    return setSearch(e.target.value);
  };

  //date


  //cancel
  const canceldata = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomen`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'เสร็จสิ้น',
          text: 'ทำการยกเลิกเรียบร้อย',
          showConfirmButton: false,
          timer: 2000
        }).then((result)=>{
          window.location.href = "/nextdays";
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const canceldatas = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomens`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'เสร็จสิ้น',
          text: 'ทำการยกเลิกเรียบร้อย',
          showConfirmButton: false,
          timer: 2000
        }).then((result)=>{
          window.location.href = "/nextdays";
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const canceldatass = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearwomenss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'เสร็จสิ้น',
          text: 'ทำการยกเลิกเรียบร้อย',
          showConfirmButton: false,
          timer: 2000
        }).then((result)=>{
          window.location.href = "/nextdays";
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const canceldataman = (AN) => {
    var data = JSON.stringify({
      AN: AN,
    });

    var config = {
      method: "delete",
      url: `${URL}/clearman`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'เสร็จสิ้น',
          text: 'ทำการยกเลิกเรียบร้อย',
          showConfirmButton: false,
          timer: 2000
        }).then((result)=>{
          window.location.href = "/nextdays";
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //cancel


  //09:00
  //one

  const [data9one, setData9one] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [icon91, setIcon91] = useState(<EventAvailable />);
  const [cancel91, setCancel91] = useState(true);
  const [cancelicon91, setCancelicon91] = useState(<CancelOutlined/>);

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one1 = [
      {
        AN: null,
        TN: null,
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        setData9one(data9one1[0]);
        setEditButton91(false);
        setIcon91(<EventAvailable color="success" />);
        setCancel91(true);
        return;
      } else if (res.data.length >= 1) {
        setData9one(res.data[0]);
        setEditButton91(true);
        setIcon91(<EventBusy />);
        setCancel91(false);
        setCancelicon91(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);
  //Modalsearch

  const [selecttn91, setSelecttn91] = useState("");
  const [selectname91, setSelectname91] = useState("");
  const [service91, setService91] = useState("");
  const handleChange91 = (event) => {
    setService91(event.target.value);
  };

  const selectpatient91 = (TN, prefix, fname, lname) => {
    setSelecttn91(TN);
    setSelectname91(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment91 = () => {
    var data = JSON.stringify({
      TN: selecttn91,
      date: data9one.date,
      time: data9one.time,
      service: service91,
      provider: data9one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open91, setOpen91] = useState(false);
  const handleOpen91 = () => setOpen91(true);
  const handleClose91 = () => setOpen91(false);

  const handleOpenin91 = () => {
    if (!service91 || !selecttn91) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment91()
      }
    })
  };

  //Modalsearch


  const handleOpencancel91 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data9one.AN)
      }
  })
  }

  //one

  //two
  const [data9two, setData9two] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [icon92, setIcon92] = useState(<EventAvailable />);
  const [cancel92, setCancel92] = useState(true);
  const [cancelicon92, setCancelicon92] = useState(<CancelOutlined/>);

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        setData9two(data9one2[0]);
        setEditButton92(false);
        setIcon92(<EventAvailable color="success" />);
        setCancel92(true);
        return;
      } else if (res.data.length >= 1) {
        setData9two(res.data[0]);
        setEditButton92(true);
        setIcon92(<EventBusy />);
        setCancel92(false);
        setCancelicon92(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn92, setSelecttn92] = useState("");
  const [selectname92, setSelectname92] = useState("");
  const [service92, setService92] = useState("");
  const handleChange92 = (event) => {
    setService92(event.target.value);
  };

  const selectpatient92 = (TN, prefix, fname, lname) => {
    setSelecttn92(TN);
    setSelectname92(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment92 = () => {
    var data = JSON.stringify({
      TN: selecttn92,
      date: data9two.date,
      time: data9two.time,
      service: service92,
      provider: data9two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open92, setOpen92] = useState(false);
  const handleOpen92 = () => setOpen92(true);
  const handleClose92 = () => setOpen92(false);

  const handleOpenin92 = () => {
    if (!service92 || !selecttn92) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment92()
      }
    })
  };

  //Modalsearch


  const handleOpencancel92 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data9two.AN)
      }
  })
  }


  //two

  //tree

  const [data9tree, setData9tree] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [icon93, setIcon93] = useState(<EventAvailable />);
  const [cancel93, setCancel93] = useState(true);
  const [cancelicon93, setCancelicon93] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "09:00",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinmentss/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData9tree(data9one3[1]);
          setEditButton93(true);
          setIcon93(<EventBusy />);
          return
        }
        setData9tree(data9one3[0]);
        setEditButton93(false);
        setIcon93(<EventAvailable color="success" />);
        setCancel93(true);
        return;
      } else if (res.data.length >= 1) {
        setData9tree(res.data[0]);
        setEditButton93(true);
        setIcon93(<EventBusy />);
        setCancel93(false);
        setCancelicon93(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn93, setSelecttn93] = useState("");
  const [selectname93, setSelectname93] = useState("");
  const [service93, setService93] = useState("");
  const handleChange93 = (event) => {
    setService93(event.target.value);
  };

  const selectpatient93 = (TN, prefix, fname, lname) => {
    setSelecttn93(TN);
    setSelectname93(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment93 = () => {
    var data = JSON.stringify({
      TN: selecttn93,
      date: data9tree.date,
      time: data9tree.time,
      service: service93,
      provider: data9tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open93, setOpen93] = useState(false);
  const handleOpen93 = () => setOpen93(true);
  const handleClose93 = () => setOpen93(false);

  const handleOpenin93 = () => {
    if (!service93 || !selecttn93) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment93()
      }
    })
  };


  //Modalsearch


  const handleOpencancel93 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data9tree.AN)
      }
  })
  }


  //tree

  //four

  const [data9four, setData9four] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [icon94, setIcon94] = useState(<EventAvailable />);
  const [cancel94, setCancel94] = useState(true);
  const [cancelicon94, setCancelicon94] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data9one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "09:00",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/manappoinment/9/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData9four(data9one4[1]);
          setEditButton94(true);
          setIcon94(<EventBusy />);
          return
        }
        setData9four(data9one4[0]);
        setEditButton94(false);
        setIcon94(<EventAvailable color="success" />);
        setCancel94(true);
        return;
      } else if (res.data.length >= 1) {
        setData9four(res.data[0]);
        setEditButton94(true);
        setIcon94(<EventBusy />);
        setCancel94(false);
        setCancelicon94(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn94, setSelecttn94] = useState("");
  const [selectname94, setSelectname94] = useState("");
  const [service94, setService94] = useState("");
  const handleChange94 = (event) => {
    setService94(event.target.value);
  };

  const selectpatient94 = (TN, prefix, fname, lname) => {
    setSelecttn94(TN);
    setSelectname94(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment94 = () => {
    var data = JSON.stringify({
      TN: selecttn94,
      date: data9four.date,
      time: data9four.time,
      service: service94,
      provider: data9four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open94, setOpen94] = useState(false);
  const handleOpen94 = () => setOpen94(true);
  const handleClose94 = () => setOpen94(false);

  const handleOpenin94 = () => {
    if (!service94 || !selecttn94) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment94()
      }
    })
  };

  //Modalsearch


  const handleOpencancel94 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data9four.AN)
      }
  })
  }


  //four

  //09:00

  //10:00
  //one
  const [data10one, setData10one] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [icon101, setIcon101] = useState(<EventAvailable />);
  const [cancel101, setCancel101] = useState(true);
  const [cancelicon101, setCancelicon101] = useState(<CancelOutlined/>);

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        setData10one(data10one1[0]);
        setEditButton101(false);
        setIcon101(<EventAvailable color="success" />);
        setCancel101(true);
        return;
      } else if (res.data.length >= 1) {
        setData10one(res.data[0]);
        setEditButton101(true);
        setIcon101(<EventBusy />);
        setCancel101(false);
        setCancelicon101(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn101, setSelecttn101] = useState("");
  const [selectname101, setSelectname101] = useState("");
  const [service101, setService101] = useState("");
  const handleChange101 = (event) => {
    setService101(event.target.value);
  };

  const selectpatient101 = (TN, prefix, fname, lname) => {
    setSelecttn101(TN);
    setSelectname101(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment101 = () => {
    var data = JSON.stringify({
      TN: selecttn101,
      date: data10one.date,
      time: data10one.time,
      service: service101,
      provider: data10one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };


  const [open101, setOpen101] = useState(false);
  const handleOpen101 = () => setOpen101(true);
  const handleClose101 = () => setOpen101(false);

  const handleOpenin101 = () => {
    if (!service101 || !selecttn101) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment101()
      }
    })
  };

  //Modalsearch


  const handleOpencancel101 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data10one.AN)
      }
  })
  }


  //one

  //two
  const [data10two, setData10two] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [icon102, setIcon102] = useState(<EventAvailable />);
  const [cancel102, setCancel102] = useState(true);
  const [cancelicon102, setCancelicon102] = useState(<CancelOutlined/>);

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        setData10two(data10one2[0]);
        setEditButton102(false);
        setIcon102(<EventAvailable color="success" />);
        setCancel102(true);
        return;
      } else if (res.data.length >= 1) {
        setData10two(res.data[0]);
        setEditButton102(true);
        setIcon102(<EventBusy />);
        setCancel102(false);
        setCancelicon102(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn102, setSelecttn102] = useState("");
  const [selectname102, setSelectname102] = useState("");
  const [service102, setService102] = useState("");
  const handleChange102 = (event) => {
    setService102(event.target.value);
  };

  const selectpatient102 = (TN, prefix, fname, lname) => {
    setSelecttn102(TN);
    setSelectname102(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment102 = () => {
    var data = JSON.stringify({
      TN: selecttn102,
      date: data10two.date,
      time: data10two.time,
      service: service102,
      provider: data10two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };


  const [open102, setOpen102] = useState(false);
  const handleOpen102 = () => setOpen102(true);
  const handleClose102 = () => setOpen102(false);

  const handleOpenin102 = () => {
    if (!service102 || !selecttn102) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment102()
      }
    })
  };

  //Modalsearch

  const handleOpencancel102 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data10two.AN)
      }
  })
  }



  //two

  //tree
  const [data10tree, setData10tree] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [icon103, setIcon103] = useState(<EventAvailable />);
  const [cancel103, setCancel103] = useState(true);
  const [cancelicon103, setCancelicon103] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "10:15",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinmentss/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData10tree(data10one3[1]);
          setEditButton103(true);
          setIcon103(<EventBusy />);
          return
        }
        setData10tree(data10one3[0]);
        setEditButton103(false);
        setIcon103(<EventAvailable color="success" />);
        setCancel103(true);
        return;
      } else if (res.data.length >= 1) {
        setData10tree(res.data[0]);
        setEditButton103(true);
        setIcon103(<EventBusy />);
        setCancel103(false);
        setCancelicon103(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn103, setSelecttn103] = useState("");
  const [selectname103, setSelectname103] = useState("");
  const [service103, setService103] = useState("");
  const handleChange103 = (event) => {
    setService103(event.target.value);
  };

  const selectpatient103 = (TN, prefix, fname, lname) => {
    setSelecttn103(TN);
    setSelectname103(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment103 = () => {
    var data = JSON.stringify({
      TN: selecttn103,
      date: data10tree.date,
      time: data10tree.time,
      service: service103,
      provider: data10tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open103, setOpen103] = useState(false);
  const handleOpen103 = () => setOpen103(true);
  const handleClose103 = () => setOpen103(false);

  const handleOpenin103 = () => {
    if (!service103 || !selecttn103) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment103()
      }
    })
  };

  //Modalsearch

  const handleOpencancel103 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data10tree.AN)
      }
  })
  }



  //tree

  //four

  const [data10four, setData10four] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [icon104, setIcon104] = useState(<EventAvailable />);
  const [cancel104, setCancel104] = useState(true);
  const [cancelicon104, setCancelicon104] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data10one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "10:15",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/manappoinment/10/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData10four(data10one4[1]);
          setEditButton104(true);
          setIcon104(<EventBusy />);
          return
        }
        setData10four(data10one4[0]);
        setEditButton104(false);
        setIcon104(<EventAvailable color="success" />);
        setCancel104(true);
        return;
      } else if (res.data.length >= 1) {
        setData10four(res.data[0]);
        setEditButton104(true);
        setIcon104(<EventBusy />);
        setCancel104(false);
        setCancelicon104(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn104, setSelecttn104] = useState("");
  const [selectname104, setSelectname104] = useState("");
  const [service104, setService104] = useState("");
  const handleChange104 = (event) => {
    setService104(event.target.value);
  };

  const selectpatient104 = (TN, prefix, fname, lname) => {
    setSelecttn104(TN);
    setSelectname104(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment104 = () => {
    var data = JSON.stringify({
      TN: selecttn104,
      date: data10four.date,
      time: data10four.time,
      service: service104,
      provider: data10four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open104, setOpen104] = useState(false);
  const handleOpen104 = () => setOpen104(true);
  const handleClose104 = () => setOpen104(false);

  const handleOpenin104 = () => {
    if (!service104 || !selecttn104) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment104()
      }
    })
  };

  //Modalsearch

  const handleOpencancel104 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data10four.AN)
      }
  })
  }


  //four

  //10:00

  //13:30

  //one
  const [data13one, setData13one] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [icon131, setIcon131] = useState(<EventAvailable />);
  const [cancel131, setCancel131] = useState(true);
  const [cancelicon131, setCancelicon131] = useState(<CancelOutlined/>);

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        setData13one(data13one1[0]);
        setEditButton131(false);
        setIcon131(<EventAvailable color="success" />);
        setCancel131(true);
        return;
      } else if (res.data.length >= 1) {
        setData13one(res.data[0]);
        setEditButton131(true);
        setIcon131(<EventBusy />);
        setCancel131(false);
        setCancelicon131(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn131, setSelecttn131] = useState("");
  const [selectname131, setSelectname131] = useState("");
  const [service131, setService131] = useState("");
  const handleChange131 = (event) => {
    setService131(event.target.value);
  };

  const selectpatient131 = (TN, prefix, fname, lname) => {
    setSelecttn131(TN);
    setSelectname131(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment131 = () => {
    var data = JSON.stringify({
      TN: selecttn131,
      date: data13one.date,
      time: data13one.time,
      service: service131,
      provider: data13one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open131, setOpen131] = useState(false);
  const handleOpen131 = () => setOpen131(true);
  const handleClose131 = () => setOpen131(false);

  const handleOpenin131 = () => {
    if (!service131 || !selecttn131) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment131()
      }
    })
  };

  //Modalsearch

  const handleOpencancel131 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data13one.AN)
      }
  })
  }


  //one

  //two
  const [data13two, setData13two] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [icon132, setIcon132] = useState(<EventAvailable />);
  const [cancel132, setCancel132] = useState(true);
  const [cancelicon132, setCancelicon132] = useState(<CancelOutlined/>);

  useEffect(() => {
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },
    ];
    const aaa = `${URL}/appoinments/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        setData13two(data13one2[0]);
        setEditButton132(false);
        setIcon132(<EventAvailable color="success" />);
        setCancel132(true);
        return;
      } else if (res.data.length >= 1) {
        setData13two(res.data[0]);
        setEditButton132(true);
        setIcon132(<EventBusy />);
        setCancel132(false);
        setCancelicon132(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn132, setSelecttn132] = useState("");
  const [selectname132, setSelectname132] = useState("");
  const [service132, setService132] = useState("");
  const handleChange132 = (event) => {
    setService132(event.target.value);
  };

  const selectpatient132 = (TN, prefix, fname, lname) => {
    setSelecttn132(TN);
    setSelectname132(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment132 = () => {
    var data = JSON.stringify({
      TN: selecttn132,
      date: data13two.date,
      time: data13two.time,
      service: service132,
      provider: data13two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open132, setOpen132] = useState(false);
  const handleOpen132 = () => setOpen132(true);
  const handleClose132 = () => setOpen132(false);

  const handleOpenin132 = () => {
    if (!service132 || !selecttn132) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment132()
      }
    })
  };

  //Modalsearch

  const handleOpencancel132 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data13two.AN)
      }
  })
  }



  //two

  //tree
  const [data13tree, setData13tree] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [icon133, setIcon133] = useState(<EventAvailable />);
  const [cancel133, setCancel133] = useState(true);
  const [cancelicon133, setCancelicon133] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "13:30",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinmentss/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData13tree(data13one3[1]);
          setEditButton133(true);
          setIcon133(<EventBusy />);
          return
        }
        setData13tree(data13one3[0]);
        setEditButton133(false);
        setIcon133(<EventAvailable color="success" />);
        setCancel133(true);
        return;
      } else if (res.data.length >= 1) {
        setData13tree(res.data[0]);
        setEditButton133(true);
        setIcon133(<EventBusy />);
        setCancel133(false);
        setCancelicon133(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn133, setSelecttn133] = useState("");
  const [selectname133, setSelectname133] = useState("");
  const [service133, setService133] = useState("");
  const handleChange133 = (event) => {
    setService133(event.target.value);
  };

  const selectpatient133 = (TN, prefix, fname, lname) => {
    setSelecttn133(TN);
    setSelectname133(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment133 = () => {
    var data = JSON.stringify({
      TN: selecttn133,
      date: data13tree.date,
      time: data13tree.time,
      service: service133,
      provider: data13tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open133, setOpen133] = useState(false);
  const handleOpen133 = () => setOpen133(true);
  const handleClose133 = () => setOpen133(false);

  const handleOpenin133 = () => {
    if (!service133 || !selecttn133) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment133()
      }
    })
  };

  //Modalsearch

  const handleOpencancel133 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data13tree.AN)
      }
  })
  }




  //tree

  //four

  const [data13four, setData13four] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [icon134, setIcon134] = useState(<EventAvailable />);
  const [cancel134, setCancel134] = useState(true);
  const [cancelicon134, setCancelicon134] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data13one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "13:30",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/manappoinment/13/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData13four(data13one4[1]);
          setEditButton134(true);
          setIcon134(<EventBusy />);
          return
        }
        setData13four(data13one4[0]);
        setEditButton134(false);
        setIcon134(<EventAvailable color="success" />);
        setCancel134(true);
        return;
      } else if (res.data.length >= 1) {
        setData13four(res.data[0]);
        setEditButton134(true);
        setIcon134(<EventBusy />);
        setCancel134(false);
        setCancelicon134(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn134, setSelecttn134] = useState("");
  const [selectname134, setSelectname134] = useState("");
  const [service134, setService134] = useState("");
  const handleChange134 = (event) => {
    setService134(event.target.value);
  };

  const selectpatient134 = (TN, prefix, fname, lname) => {
    setSelecttn134(TN);
    setSelectname134(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment134 = () => {
    var data = JSON.stringify({
      TN: selecttn134,
      date: data13four.date,
      time: data13four.time,
      service: service134,
      provider: data13four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open134, setOpen134] = useState(false);
  const handleOpen134 = () => setOpen134(true);
  const handleClose134 = () => setOpen134(false);

  const handleOpenin134 = () => {
    if (!service134 || !selecttn134) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment134()
      }
    })
  };

  //Modalsearch

  const handleOpencancel134 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data13four.AN)
      }
  })
  }


  //four

  //13:30

  //14:45
  //one
  const [data14one, setData14one] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [icon141, setIcon141] = useState(<EventAvailable />);
  const [cancel141, setCancel141] = useState(true);
  const [cancelicon141, setCancelicon141] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one1 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData14one(data14one1[1]);
          setEditButton141(true);
          setIcon141(<EventBusy />);
          return
        }
        setData14one(data14one1[0]);
        setEditButton141(false);
        setIcon141(<EventAvailable color="success" />);
        setCancel141(true);
        return;
      } else if (res.data.length >= 1) {
        setData14one(res.data[0]);
        setEditButton141(true);
        setIcon141(<EventBusy />);
        setCancel141(false);
        setCancelicon141(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn141, setSelecttn141] = useState("");
  const [selectname141, setSelectname141] = useState("");
  const [service141, setService141] = useState("");
  const handleChange141 = (event) => {
    setService141(event.target.value);
  };

  const selectpatient141 = (TN, prefix, fname, lname) => {
    setSelecttn141(TN);
    setSelectname141(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment141 = () => {
    var data = JSON.stringify({
      TN: selecttn141,
      date: data14one.date,
      time: data14one.time,
      service: service141,
      provider: data14one.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinments`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open141, setOpen141] = useState(false);
  const handleOpen141 = () => setOpen141(true);
  const handleClose141 = () => setOpen141(false);

  const handleOpenin141 = () => {
    if (!service141 || !selecttn141) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment141()
      }
    })
  };


  //Modalsearch

  const handleOpencancel141 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldata(data14one.AN)
      }
  })
  }



  //one

  //two
  const [data14two, setData14two] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [icon142, setIcon142] = useState(<EventAvailable />);
  const [cancel142, setCancel142] = useState(true);
  const [cancelicon142, setCancelicon142] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one2 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinments/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData14two(data14one2[1]);
          setEditButton142(true);
          setIcon142(<EventBusy />);
          return
        }
        setData14two(data14one2[0]);
        setEditButton142(false);
        setIcon142(<EventAvailable color="success" />);
        setCancel142(true);
        return;
      } else if (res.data.length >= 1) {
        setData14two(res.data[0]);
        setEditButton142(true);
        setIcon142(<EventBusy />);
        setCancel142(false);
        setCancelicon142(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn142, setSelecttn142] = useState("");
  const [selectname142, setSelectname142] = useState("");
  const [service142, setService142] = useState("");
  const handleChange142 = (event) => {
    setService142(event.target.value);
  };

  const selectpatient142 = (TN, prefix, fname, lname) => {
    setSelecttn142(TN);
    setSelectname142(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment142 = () => {
    var data = JSON.stringify({
      TN: selecttn142,
      date: data14two.date,
      time: data14two.time,
      service: service142,
      provider: data14two.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open142, setOpen142] = useState(false);
  const handleOpen142 = () => setOpen142(true);
  const handleClose142 = () => setOpen142(false);


  const handleOpenin142 = () => {
    if (!service142 || !selecttn142) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment142()
      }
    })
  };


  //Modalsearch


  const handleOpencancel142 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatas(data14two.AN)
      }
  })
  }



  //two

  //tree
  const [data14tree, setData14tree] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [icon143, setIcon143] = useState(<EventAvailable />);
  const [cancel143, setCancel143] = useState(true);
  const [cancelicon143, setCancelicon143] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one3 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "หญิง",
        date: bbb,
        time: "14:45",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/appoinmentss/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData14tree(data14one3[1]);
          setEditButton143(true);
          setIcon143(<EventBusy />);
          return
        }
        setData14tree(data14one3[0]);
        setEditButton143(false);
        setIcon143(<EventAvailable color="success" />);
        setCancel143(true);
        return;
      } else if (res.data.length >= 1) {
        setData14tree(res.data[0]);
        setEditButton143(true);
        setIcon143(<EventBusy />);
        setCancel143(false);
        setCancelicon143(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn143, setSelecttn143] = useState("");
  const [selectname143, setSelectname143] = useState("");
  const [service143, setService143] = useState("");
  const handleChange143 = (event) => {
    setService143(event.target.value);
  };

  const selectpatient143 = (TN, prefix, fname, lname) => {
    setSelecttn143(TN);
    setSelectname143(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment143 = () => {
    var data = JSON.stringify({
      TN: selecttn143,
      date: data14tree.date,
      time: data14tree.time,
      service: service143,
      provider: data14tree.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/apppoinmentsss`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open143, setOpen143] = useState(false);
  const handleOpen143 = () => setOpen143(true);
  const handleClose143 = () => setOpen143(false);


  const handleOpenin143 = () => {
    if (!service143 || !selecttn143) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment143()
      }
    })
  };


  //Modalsearch


  const handleOpencancel143 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldatass(data14tree.AN)
      }
  })
  }


  //tree

  //four

  const [data14four, setData14four] = useState([]);
  const [editButton144, setEditButton144] = useState(false);
  const [icon144, setIcon144] = useState(<EventAvailable/>);
  const [cancel144, setCancel144] = useState(true);
  const [cancelicon144, setCancelicon144] = useState(<CancelOutlined/>);

  useEffect(() => {
    const roletoken = localStorage.getItem('role')
    const bbb =
      startDate.getDate() +
      "-" +
      (startDate.getMonth() + 1) +
      "-" +
      startDate.getFullYear();
    const data14one4 = [
      {
        fname: "ว่าง",
        lname: null,
        service: "ว่าง",
        provider: "ชาย",
        date: bbb,
        time: "14:45",
        prefix: null,
      },{ fname: null, lname: null, service: null, provider: null, date:null ,time:null ,prefix:null },
    ];
    const aaa = `${URL}/manappoinment/14/`;
    const oneURL = aaa + bbb;
    axios.get(oneURL).then((res) => {
      if (res.data.length === 0) {
        if((startDate.getDay()===0 || startDate.getDay()===6) && (roletoken === "admin")){
          setData14four(data14one4[1]);
          setEditButton144(true);
          setIcon144(<EventBusy />);
          return
        }
        setData14four(data14one4[0]);
        setEditButton144(false);
        setIcon144(<EventAvailable color="success" />);
        setCancel144(true);
        return;
      } else if (res.data.length === 1) {
        setData14four(res.data[0]);
        setEditButton144(true);
        setIcon144(<EventBusy />);
        setCancel144(false);
        setCancelicon144(<CancelOutlined color="warning"/>)
      }
    });
  }, [startDate,URL]);

  //Modalsearch

  const [selecttn144, setSelecttn144] = useState("");
  const [selectname144, setSelectname144] = useState("");
  const [service144, setService144] = useState("");
  const handleChange144 = (event) => {
    setService144(event.target.value);
  };

  const selectpatient144 = (TN, prefix, fname, lname) => {
    setSelecttn144(TN);
    setSelectname144(`${prefix}${fname} ${lname}`);
  };

  const addmanaappoinment144 = () => {
    var data = JSON.stringify({
      TN: selecttn144,
      date: data14four.date,
      time: data14four.time,
      service: service144,
      provider: data14four.provider,
    });

    var config = {
      method: "post",
      url: `${URL}/manapppoinment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'นัดหมายเรียบร้อย ',
          text:'กรุณาตรวจสอบความถูกต้องด้วยตนเองอีกครั้ง',
          showConfirmButton: false,
          timer: 2500
        }).then((result)=>{
          window.location = "/nextdays";
        })
      })
      .catch(function (error) {
        alert(error);
      });
  };

  const [open144, setOpen144] = useState(false);
  const handleOpen144 = () => setOpen144(true);
  const handleClose144 = () => setOpen144(false);

  const handleOpenin144 = () => {
    if (!service144 || !selecttn144) {
      Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text:'กรุณากรอก TN หรือ หัตถการให้ถูกต้อง',
        showConfirmButton: false,
        timer: 2500
      })
      return;
    }
    Swal.fire({
      title: 'ยืนยันการนัดหมาย',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        addmanaappoinment144()
      }
    })
  };

  // const handleClosein144 = () => setOpenin144(false);

  //Modalsearch

  const handleOpencancel144 = () =>  {
    Swal.fire({
      title: 'ยกเลิกการเข้ารับบริการ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ปิด'
    }).then((result) => {
      if (result.isConfirmed) {
        canceldataman(data14four.AN)
      }
  })
  }

  //four

  //14:45

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ margin: "10px 10px 10px 10px" }}>
          <DatePicker
            onChange={(Date) => setStartDate(Date)}
            value={startDate}
            format="dd-MM-y"
            locale="th-TH"
            calendarClassName="calendarclass"
            className="picker"
          />
          <span style={{marginLeft:'10px',fontSize:"small"}}>{dayname}</span>
        </h2>
      </div>
      <div className="testtest">
        <table className="ondate" style={{width:'400px'}}>
          <tbody>
            <tr className="head">
              <th className="headtime">เวลา</th>
              <th className="headstatus" style={{width:'15%'}}>ชื่อผู้รับบริการ</th>
              <th className="headprovider">ผู้นวด</th>
              <th className="headtime">สถานะ</th>
            </tr>
            <tr className="bodynine">
              <td className="bodytime">09:00 น.</td>
              <td className="bodytime">{data9one.fname}</td>
              <td className="bodyprovider">{data9one.provider}</td>
              <td className="bodytime">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton91}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen91}
                >
                  {icon91}
                </Button>
                <Modal
                  open={open91}
                  onClose={handleClose91}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient91(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn91}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname91}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data9one.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data9one.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data9one.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service91}
                                      onChange={handleChange91}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin91}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel91}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel91}
                >
                {cancelicon91}
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
            <td className="bodytime">09:00 น.</td>
              <td className="bodytime">{data9two.fname}</td>
              <td className="bodyprovider">{data9two.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton92}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen92}
                >
                  {icon92}
                </Button>
                <Modal
                  open={open92}
                  onClose={handleClose92}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient92(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn92}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname92}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data9two.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data9two.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data9two.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service92}
                                      onChange={handleChange92}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin92}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel92}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel92}
                >
                {cancelicon92}
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
            <td className="bodytime">09:00 น.</td>
              <td className="bodytime">{data9tree.fname}</td>
              <td className="bodyprovider">{data9tree.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton93}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen93}
                >
                  {icon93}
                </Button>
                <Modal
                  open={open93}
                  onClose={handleClose93}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient93(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn93}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname93}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data9tree.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data9tree.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data9tree.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service93}
                                      onChange={handleChange93}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin93}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel93}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel93}
                >
                {cancelicon93}
                </Button>
              </td>
            </tr>
            <tr className="bodynine">
            <td className="bodytime">09:00 น.</td>
              <td className="bodytime">{data9four.fname}</td>
              <td className="bodyprovider">{data9four.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton94}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen94}
                >
                  {icon94}
                </Button>
                <Modal
                  open={open94}
                  onClose={handleClose94}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient94(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn94}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname94}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data9four.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data9four.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data9four.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service94}
                                      onChange={handleChange94}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin94}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel94}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel94}
                >
                {cancelicon94}
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
              <td className="bodytime">10:15 น.</td>
              <td className="bodytime">{data10one.fname}</td>
              <td className="bodyprovider">{data10one.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton101}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen101}
                >
                  {icon101}
                </Button>
                <Modal
                  open={open101}
                  onClose={handleClose101}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient101(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn101}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname101}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data10one.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data10one.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data10one.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service101}
                                      onChange={handleChange101}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin101}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel101}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel101}
                >
                {cancelicon101}
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
            <td className="bodytime">10:15 น.</td>
              <td className="bodytime">{data10two.fname}</td>
              <td className="bodyprovider">{data10two.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton102}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen102}
                >
                  {icon102}
                </Button>
                <Modal
                  open={open102}
                  onClose={handleClose102}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient102(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn102}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname102}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data10two.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data10two.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data10two.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service102}
                                      onChange={handleChange102}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin102}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel102}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel102}
                >
                {cancelicon102}
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
            <td className="bodytime">10:15 น.</td>
              <td className="bodytime">{data10tree.fname}</td>
              <td className="bodyprovider">{data10tree.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton103}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen103}
                >
                  {icon103}
                </Button>
                <Modal
                  open={open103}
                  onClose={handleClose103}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient103(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn103}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname103}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data10tree.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data10tree.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data10tree.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service103}
                                      onChange={handleChange103}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin103}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel103}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel103}
                >
                {cancelicon103}
                </Button>
              </td>
            </tr>
            <tr className="bodyten">
            <td className="bodytime">10:15 น.</td>
              <td className="bodytime">{data10four.fname}</td>
              <td className="bodyprovider">{data10four.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton104}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen104}
                >
                  {icon104}
                </Button>
                <Modal
                  open={open104}
                  onClose={handleClose104}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient104(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn104}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname104}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data10four.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data10four.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data10four.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service104}
                                      onChange={handleChange104}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin104}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel104}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel104}
                >
                {cancelicon104}
                </Button>
              </td>
            </tr>
            <tr className="body13">
              <td className="bodytime">13:30 น.</td>
              <td className="bodytime">{data13one.fname}</td>
              <td className="bodyprovider">{data13one.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton131}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen131}
                >
                  {icon131}
                </Button>
                <Modal
                  open={open131}
                  onClose={handleClose131}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient131(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn131}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname131}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data13one.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data13one.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data13one.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service131}
                                      onChange={handleChange131}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin131}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel131}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel131}
                >
                {cancelicon131}
                </Button>
              </td>
            </tr>
            <tr className="body13">
            <td className="bodytime">13:30 น.</td>
              <td className="bodytime">{data13two.fname}</td>
              <td className="bodyprovider">{data13two.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton132}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen132}
                >
                  {icon132}
                </Button>
                <Modal
                  open={open132}
                  onClose={handleClose132}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient132(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn132}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname132}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data13two.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data13two.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data13two.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service132}
                                      onChange={handleChange132}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin132}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel132}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel132}
                >
                {cancelicon132}
                </Button>
              </td>
            </tr>
            <tr className="body13">
            <td className="bodytime">13:30 น.</td>
              <td className="bodytime">{data13tree.fname}</td>
              <td className="bodyprovider">{data13tree.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton133}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen133}
                >
                  {icon133}
                </Button>
                <Modal
                  open={open133}
                  onClose={handleClose133}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient133(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn133}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname133}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data13tree.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data13tree.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data13tree.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service133}
                                      onChange={handleChange133}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin133}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel133}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel133}
                >
                {cancelicon133}
                </Button>
              </td>
            </tr>
            <tr className="body13">
            <td className="bodytime">13:30 น.</td>
              <td className="bodytime">{data13four.fname}</td>
              <td className="bodyprovider">{data13four.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton134}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen134}
                >
                  {icon134}
                </Button>
                <Modal
                  open={open134}
                  onClose={handleClose134}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient134(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn134}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname134}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data13four.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data13four.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data13four.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service134}
                                      onChange={handleChange134}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin134}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel134}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel134}
                >
                {cancelicon134}
                </Button>
              </td>
            </tr>
            <tr className="body14">
              <td className="bodytime">14:45 น.</td>
              <td className="bodytime">{data14one.fname}</td>
              <td className="bodyprovider">{data14one.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton141}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen141}
                >
                  {icon141}
                </Button>
                <Modal
                  open={open141}
                  onClose={handleClose141}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient141(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn141}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname141}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data14one.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data14one.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data14one.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service141}
                                      onChange={handleChange141}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin141}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel141}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel141}
                >
                {cancelicon141}
                </Button>
              </td>
            </tr>
            <tr className="body14">
            <td className="bodytime">14:45 น.</td>
              <td className="bodytime">{data14two.fname}</td>
              <td className="bodyprovider">{data14two.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton142}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen142}
                >
                  {icon142}
                </Button>
                <Modal
                  open={open142}
                  onClose={handleClose142}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient142(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn142}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname142}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data14two.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data14two.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data14two.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service142}
                                      onChange={handleChange142}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin142}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel142}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel142}
                >
                {cancelicon142}
                </Button>
              </td>
            </tr>
            <tr className="body14">
            <td className="bodytime">14:45 น.</td>
              <td className="bodytime">{data14tree.fname}</td>
              <td className="bodyprovider">{data14tree.provider}</td>
              <td className="bodystatus">
              <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton143}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen143}
                >
                  {icon143}
                </Button>
                <Modal
                  open={open143}
                  onClose={handleClose143}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              label="ค้นหา..."
                              variant="outlined"
                              onChange={testtt}
                              size="small"
                            />
                          </Box>
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >สิทธิ์</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient143(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 2 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn143}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname143}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data14tree.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    value={`${data14tree.time}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    value={`${data14tree.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service143}
                                      onChange={handleChange143}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin143}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel143}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel143}
                >
                {cancelicon143}
                </Button>
              </td>
            </tr>
            <tr className="body14">
            <td className="bodytime">14:45 น.</td>
              <td className="bodytime">{data14four.fname}</td>
              <td className="bodyprovider">{data14four.provider}</td>
              <td className="bodystatus">
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={editButton144}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:"center", alignItems:"center" }}
                  onClick={handleOpen144}
                >
                  {icon144}
                </Button>
                <Modal
                  open={open144}
                  onClose={handleClose144}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={stylee}>
                      <Paper>
                        <Box display="flex">
                          <TextField
                            fullWidth
                            id="fullWidth"
                            label="ค้นหา..."
                            onChange={testtt}
                            size="small"
                            variant="outlined"
                          />
                        </Box>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"4px"}}>
                        <TableContainer sx={{ height: "160px" , maxWidth: "320px" }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead  >
                              <TableRow>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0",backgroundColor:"#1bb3b3"}} >ชื่อสกุล</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px",maxHeight:"30px", padding:"5px 0 5px 0",backgroundColor:"#1bb3b3"}}>TN</TableCell>
                                <TableCell align="center" style={{maxWidth: "60px", padding:"5px 5px 5px 5px",backgroundColor:"#1bb3b3"}}>เลือก</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.filter((e) => {
                                  return (
                                    e.fname.includes(search) ||
                                    e.lname.includes(search) ||
                                    e.TN.toString().includes(search) ||
                                    e.tel.includes(search)
                                  );
                                })
                                .map((item) => {
                                return (
                                  <TableRow key={item.TN}>
                                    <TableCell align="center" style={{maxWidth: "60px" ,padding:"5px 0 5px 0"}}>
                                      {item.TN}
                                    </TableCell >
                                    <TableCell style={{padding:"0 0 0 5px"}}>
                                      {item.fname} {item.lname}
                                    </TableCell>
                                    <TableCell align="center" style={{maxHeight:"30px", padding:"0 0 0 0"}} >{item.role}</TableCell>
                                    <TableCell align="center" style={{maxWidth: "60px",padding:"5px 5px 5px 5px"}}>
                                    <Button
                                            variant="contained"
                                            size="small"
                                            style={{ margin: "0 0 0 8px",display:"contents" }}
                                            onClick={() => {
                                              return selectpatient144(
                                                item.TN,
                                                item.prefix,
                                                item.fname,
                                                item.lname
                                              );
                                            }}
                                          >
                                            <Send color="secondary" size="small"/>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </div>
                      </Paper>
                      <Paper style={{ marginTop: 10 }}>
                        <Grid item xs={4} sm={4}>
                          <CssBaseline />
                          <Container maxWidth="sm" sx={{ p: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                            ลงนัดคนไข้
                            </Typography>
                            <Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="TN"
                                    label="TN"
                                    size="small"
                                    variant="standard"
                                    placeholder="กรุณากรอก TN"
                                    value={`${selecttn144}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="fullname"
                                    label="ชื่อสกุล"
                                    size="small"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${selectname144}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="date"
                                    label="วันที่"
                                    size="small"
                                    variant="standard"
                                    placeholder="กรุณากรอกชื่อ-สกุล"
                                    value={`${data14four.date}`}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <TextField
                                    id="เวลา"
                                    label="time"
                                    size="small"
                                    value={`${data14four.time} น.`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                  <TextField
                                    id="provider"
                                    label="เจ้าหน้าที่"
                                    size="small"
                                    value={`${data14four.provider}`}
                                    variant="standard"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <FormControl variant="standard" size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      หัตถการ
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={service144}
                                      onChange={handleChange144}
                                      label="service"
                                    >
                                      <MenuItem value="นวด">นวด</MenuItem>
                                      <MenuItem value="ประคบ">ประคบ</MenuItem>
                                      <MenuItem value="อบสมุนไพร">
                                        อบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดและประคบ">
                                        นวดและประคบ
                                      </MenuItem>
                                      <MenuItem value="นวดและอบสมุนไพร">
                                        นวดและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="ประคบและอบสมุนไพร">
                                        ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวด ประคบและอบสมุนไพร">
                                        นวด ประคบและอบสมุนไพร
                                      </MenuItem>
                                      <MenuItem value="นวดเท้าเพื่อสุขภาพ">
                                        นวดเท้าเพื่อสุขภาพ
                                      </MenuItem>
                                      <MenuItem value="เจ้าหน้าที่ไม่พร้อมให้บริการ">
                                        เจ้าหน้าที่ไม่พร้อมให้บริการ
                                      </MenuItem>
                                      <MenuItem value="วันหยุดราชการหรืออื่นๆ">
                                        วันหยุดราชการหรืออื่นๆ
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={handleOpenin144}
                                  >
                                    นัดหมาย
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Paper>
                  </Box>
                </Modal>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={cancel144}
                  style={{ margin: "0 0 0 4px" , display:"contents" , justifyContent:'center', alignItems:"center"  }}
                  onClick={handleOpencancel144}
                >
                {cancelicon144}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Newdatessss;
