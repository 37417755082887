import { Cancel, Send } from "@mui/icons-material";
import { Button} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import './reviews.css'


const Reviews = () => {
    const navigate = useNavigate()
    const URL = "https://testapi.thaipadi.org"
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    
    //     var config = {
    //       method: "post",
    //       url: `${URL}/authen`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     };
    
    //     axios(config)
    //       .then(function (response) {
    //         if (response.data.status === "OK") {
    //           // alert('login success')
    //         } else {
    //           Swal.fire({
    //             position: 'top-end',
    //             icon: 'warning',
    //             title: 'กรุณาเข้าสู่ระบบก่อนใช้งาน',
    //             showConfirmButton: false,
    //             timer: 3000
    //           }).then((result)=>{
    //             localStorage.clear();
    //             window.location = "/login";
    //           })
    //         }
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   }, [URL]);

      const [steppoint , setSteppoint] = useState(0)
      const [providerpoint , setProviderpoint] = useState(0)
      const [locationpoint , setLocationpoint] = useState(0)
      const [covidpoint , setCovidpoint] = useState(0)
      const [comment , setComment] = useState('ปฏิเสธการให้ข้อเสนอแนะ')

      console.log(steppoint)
      console.log(providerpoint)
      console.log(locationpoint)
      console.log(covidpoint)
      console.log(comment)

      const ratingstep=(number)=>{
          setSteppoint(number)
      }
      const ratingprovider=(number)=>{
          setProviderpoint(number)
      }
      const ratinglocation=(number)=>{
          setLocationpoint(number)
      }
      const ratingcovid=(number)=>{
          setCovidpoint(number)
      }
      const textpoint=(e)=>{
          setComment(e.target.value)
      }

      const checkpoint =(e)=>{
        e.preventDefault();
        if(steppoint === 0 || providerpoint === 0 || locationpoint ===0 || covidpoint === 0){
          Swal.fire({
            icon: 'warning',
            title: 'กรุณาประเมินให้ครบทุกช่อง',
            showConfirmButton: false,
            timer: 2000
          })
        } else if (steppoint !== 0 || providerpoint !== 0 || locationpoint !==0 || covidpoint !== 0){
          endpointSubmit()
        }
      }


      const endpointSubmit = () => {

        const jsondata = JSON.stringify({
          step: steppoint,
          provider: providerpoint,
          location: locationpoint,
          covid: covidpoint,
          comment: comment
        });

        var config = {
          method: "post",
          url: `${URL}/reviews`,
          headers: {
            "Content-Type": "application/json",
          },
          data: jsondata,
        };

        axios(config)
          .then(function (response) {
            if (response.data.status === "OK") {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'เสร็จสิ้น!',
                text:'ประเมินความพึงพอใจเรียบร้อยแล้ว',
                showConfirmButton: false,
                timer: 2000
              }).then((result)=>{
                navigate("/");
                return
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      };
      const cancelreviews =()=>{
        navigate("/")
      }

      
  return (
    <div className="reviewspages">
      <form className="containers" onSubmit={checkpoint}>
        <div style={{display:'flex',justifyContent:'Right',alignItems:'Right',width:'100%'}}>
        <Button
              onClick={cancelreviews}>
              <Cancel size='small' color="error"/>
        </Button>
        </div>
        <h1 style={{textAlign:"center",color:'antiquewhite',fontSize:'24px',marginBottom:'10px'}}>
          <p>แบบประเมินความพึงพอใจ</p><p>คลินิกแพทย์แผนไทย</p> 
        </h1>       
        <div className="skiis">
          <h3 className="name">&nbsp;&nbsp;ด้านขั้นตอนการให้บริการ</h3>
          {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>
          <Stack spacing={1}>
            <Rating name="hover-feedback" defaultValue={0.5} precision={0.5} size="large" />
          </Stack>
          </div> */}
          <div className="rating">
            <input type="radio" name="html" value="5" onClick={()=>{ratingstep(5)}}/>
            <input type="radio" name="html" value="4.5" onClick={()=>{ratingstep(4.5)}} />
            <input type="radio" name="html" value="4" onClick={()=>{ratingstep(4)}} />
            <input type="radio" name="html" value="3.5" onClick={()=>{ratingstep(3.5)}}/>
            <input type="radio" name="html" value="3" onClick={()=>{ratingstep(3)}}/>
            <input type="radio" name="html" value="2.5" onClick={()=>{ratingstep(2.5)}}/>
            <input type="radio" name="html" value="2" onClick={()=>{ratingstep(2)}}/>
            <input type="radio" name="html" value="1.5" onClick={()=>{ratingstep(1.5)}}/>
            <input type="radio" name="html" value="1" onClick={()=>{ratingstep(1)}}/>
            <input type="radio" name="html" value="0.5" onClick={()=>{ratingstep(0.5)}}/>
          </div>
        </div>
        <div className="skiis">
          <h3 className="name">&nbsp;&nbsp;ด้านเจ้าหน้าที่ผู้ให้บริการ</h3>
          <div className="rating">
            <input type="radio" name="css" value="5" onClick={()=>{ratingprovider(5)}} />
            <input type="radio" name="css" value="4.5" onClick={()=>{ratingprovider(4.5)}}/>
            <input type="radio" name="css" value="4" onClick={()=>{ratingprovider(4)}}/>
            <input type="radio" name="css" value="3.5" onClick={()=>{ratingprovider(3.5)}}/>
            <input type="radio" name="css" value="3" onClick={()=>{ratingprovider(3)}}/>
            <input type="radio" name="css" value="2.5" onClick={()=>{ratingprovider(2.5)}}/>
            <input type="radio" name="css" value="2" onClick={()=>{ratingprovider(2)}}/>
            <input type="radio" name="css" value="1.5" onClick={()=>{ratingprovider(1.5)}}/>
            <input type="radio" name="css" value="1" onClick={()=>{ratingprovider(1)}}/>
            <input type="radio" name="css" value="0.5" onClick={()=>{ratingprovider(0.5)}}/>
          </div>
        </div>
        <div className="skiis">
          <h3 className="name">&nbsp;&nbsp;ด้านอาคารสถานที่</h3>
          <div className="rating">
            <input type="radio" name="js" value="5" onClick={()=>{ratinglocation(5)}}/>
            <input type="radio" name="js" value="4.5" onClick={()=>{ratinglocation(4.5)}}/>
            <input type="radio" name="js" value="4" onClick={()=>{ratinglocation(4)}}/>
            <input type="radio" name="js" value="3.5" onClick={()=>{ratinglocation(3.5)}}/>
            <input type="radio" name="js" value="3" onClick={()=>{ratinglocation(3)}}/>
            <input type="radio" name="js" value="2.5" onClick={()=>{ratinglocation(2.5)}}/>
            <input type="radio" name="js" value="2" onClick={()=>{ratinglocation(2)}}/>
            <input type="radio" name="js" value="1.5" onClick={()=>{ratinglocation(1.5)}}/>
            <input type="radio" name="js" value="1" onClick={()=>{ratinglocation(1)}}/>
            <input type="radio" name="js" value="0.5" onClick={()=>{ratinglocation(0.5)}}/>
          </div>
        </div>
        <div className="skiis">
          <h3 className="name">&nbsp;&nbsp;ด้านมาตรการป้องกันโควิด-19</h3>
          <div className="rating">
            <input type="radio" name="clinic" value="5" onClick={()=>{ratingcovid(5)}}/>
            <input type="radio" name="clinic" value="4.5" onClick={()=>{ratingcovid(4.5)}}/>
            <input type="radio" name="clinic" value="4" onClick={()=>{ratingcovid(4)}}/>
            <input type="radio" name="clinic" value="3.5" onClick={()=>{ratingcovid(3.5)}}/>
            <input type="radio" name="clinic" value="3" onClick={()=>{ratingcovid(3)}}/>
            <input type="radio" name="clinic" value="2.5" onClick={()=>{ratingcovid(2.5)}}/>
            <input type="radio" name="clinic" value="2" onClick={()=>{ratingcovid(2)}}/>
            <input type="radio" name="clinic" value="1.5" onClick={()=>{ratingcovid(1.5)}}/>
            <input type="radio" name="clinic" value="1" onClick={()=>{ratingcovid(1)}}/>
            <input type="radio" name="clinic" value="0.5" onClick={()=>{ratingcovid(0.5)}}/>
          </div>
        </div>
        <div className="skiiss">
          <h3 className="name">&nbsp;&nbsp;ข้อเสนอแนะ</h3>
          <div className="text">
            <input
              type="text"
              name="text"
              style={{textAlign: "left"}}
              placeholder="&nbsp;&nbsp;ใส่หรือไม่ใส่ก็ได้"
              onChange={textpoint}
            />
          </div>
        </div>
        <div className="button" style={{marginTop:'20px'}}>
        <Button
              type="submit"
              fullWidth
              variant="contained"
              className="buttonreviews"
              style={{paddingRight:'50px',paddingLeft:'50px',fontSize:'20px',backgroundColor:'#e79129',}}
            >
              <Send size='small'/>&nbsp;ส่ง
        </Button>
        </div>
      </form>
    </div>
  );
};
export default Reviews;
