import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useState,useEffect} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';


const Usercreate = (props)=>{
  const URL = 'https://testapi.thaipadi.org'

  useEffect(() => {

    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    
    var config = {
      method: 'post',
      url: `${URL}/authen`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.status === 'OK' && (role === 'admin' || role === 'root' )){
        // alert('login success')
      } else if(response.data.status === 'OK' && (role !== 'admin' || role !== 'root' )){
                Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            window.location.href = "/";
          })
        }   
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'ท่านไม่ได้รับสิทะิ์ในการเข้าถึงส่วนนี้',
            showConfirmButton: false,
            timer: 3000
          }).then((result)=>{
            localStorage.clear();
            window.location.href = "/login";
          })
        }
      })
    .catch(function (error) {
      console.log(error);
    });

    }, [URL]);



//adusers
    const [prefix, setPrefix] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [tel, setTel] = useState('');

    const prefixx=(event)=>{
        setPrefix(event.target.value)
    }


  
    const submitform =(event)=>{
        event.preventDefault();
        axios
        .post(`${URL}/register`, {
          prefix: prefix,
          fname: fname,
          lname: lname,
          tel: tel,
          password: tel,
        })
        .then(function (response) {
          if (response.data['status'] === 'OK'){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'เพิ่มสมาชิกเรียบร้อย',
              showConfirmButton: false,
              timer: 1500
            }).then((result)=>{
              props.getuser()
              return
            })
          }
          else if (response.data.message.sqlState === '23000'){
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'ผิดพลาด',
              text: "เบอร์โทรศัพท์หรือเลขสมาชิกมีผู้อื่นใช้แล้ว",
              showConfirmButton: false,
              timer: 2500
            }).then((result)=>{
              props.getuser()
              props.close(false)
              return
            })
          }
          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'success',
          //   title: 'เพิ่มสมาชิกเรียบร้อย',
          //   showConfirmButton: false,
          //   timer: 1500
          // }).then((result)=>{
          //   window.location.href = '/users'
          // })
        })
        .catch(function (error) {
          alert(error);
        });
    }

    return(
        <div>
        <CssBaseline />
        <Container maxWidth="sm" sx={{p:2}}>
          <Typography variant='h6' gutterBottom component="div">
                สมัครสมาชิก
          </Typography>
          <form onSubmit={submitform}>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3.5} sm={3.5}>
                        <TextField id="prefixs" label="คำนำหน้า" variant="outlined" fullWidth required onChange={prefixx} value={prefix}/>
                    </Grid>
                    <Grid item xs={4.25} sm={4.25}>
                        <TextField id="fnames" label="ชื่อ" variant="outlined" fullWidth required onChange={(event)=>{setFname(event.target.value)}} value={fname}/>
                    </Grid>
                    <Grid item xs={4.25} sm={4.25}>
                        <TextField id="lnames" label="นามสกุล" variant="outlined" fullWidth required onChange={(event)=>{setLname(event.target.value)}} value={lname}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField id="tels" label="เบอร์โทรศัพท์" type="number" variant="outlined" fullWidth required onChange={(event)=>{setTel(event.target.value)}} value={tel}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField id="passwords" type="password" label="รหัสผ่าน" variant="outlined" fullWidth required value={tel}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Button type='submit' variant="contained" color="primary" size="large" fullWidth>ยืนยัน</Button>
                    </Grid>
                </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    )
}
export default Usercreate;
