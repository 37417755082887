import './footer.css'

const Footer=()=>{
    return(
        <footer>
            {/* <div className='clinic'>
                    <div className='clinicname'>Thai Padi Contect</div>
                    <div className='clinictel'>Tel: 0902099333</div>
                    <div className='clinictel'>email: ttm.sungaipadi@gmail.com</div>
            </div> */}
            <div className='dev'>
                <div className='devname'><p className='namecradit'>Create And Develop</p></div>
                <div className='devname'><p className='namecradit'>Arofat Sabuding</p></div>
                <div className='devcradit'><p className='telcradit'>Tel: 0902099333</p><p className='emailcradit'>email: arofat.sa@gmail.com </p></div>
            </div>
        </footer>
    )
}
export default Footer;

