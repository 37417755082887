import React from "react"
import Footer from "../fronthome/footer/footer"
import Navback from "./Navbarback"
import Newdates from "./newdates"
import {Paper,} from "@mui/material"
import Newdatess from "./newdatess"
import Newdatesss from "./newdatesss"
import Newdatessss from "./newdatessss"
import Newdatesssss from "./newdatesssss"
import Newdatessssss from "./newdatessssss"
import Newdatesssssss from "./newdatesssssss"
import Newdatessssssss from "./newdatessssssss"
import Newdatesssssssss from "./newdatesssssssss"
import Newdatessssssssss from "./newdatessssssssss"
import Newdatesssssssssss from "./newdatesssssssssss"
import Newdatessssssssssss from "./newdatessssssssssss"


const Tomorowday = () =>{



    return(
        <div >
            <Navback/>
                <div style={{display:'flex',justifyContent:'center'}}>
                <Paper style={{marginTop:'100px', width:"95%" ,marginBottom:'10px'}} >
                    <div className="searchback" style={{justifyContent:'center',marginBottom:'10px'}}>
                        <div style={{marginRight:'8px'}}> 
                            <Newdates/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatess/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatesss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatessss/>
                        </div>
                    </div>
                </Paper>
                </div>
                <div style={{display:'flex',justifyContent:'center'}}>
                <Paper style={{width:"95%" ,marginBottom:'10px'}} >
                    <div className="searchback" style={{justifyContent:'center',marginBottom:'10px'}}>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatesssss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatessssss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatesssssss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatessssssss/>
                        </div>
                    </div>
                </Paper>
                </div>
                <div style={{display:'flex',justifyContent:'center'}}>
                <Paper style={{width:"95%" ,marginBottom:'80px'}} >
                    <div className="searchback" style={{justifyContent:'center',marginBottom:'10px'}}>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatesssssssss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatessssssssss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatesssssssssss/>
                        </div>
                        <div style={{marginRight:'8px'}}> 
                            <Newdatessssssssssss/>
                        </div>
                    </div>
                </Paper>
                </div>
            <Footer/>
        </div>
 
    )
}
export default Tomorowday;
