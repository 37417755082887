import Navbar from "./Navbar/Navbar";
import Footer from "./footer/footer";
import Sidebar from "./Navbar/sidebar";
// import { useEffect } from "react";
// import axios from "axios";
const Homeline = () => {

  // useEffect(() => {

  //   const token = localStorage.getItem('token')


  //   var config = {
  //     method: 'post',
  //     url: 'https://testapi.thaipadi.org/authen',
  //     headers: { 
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     },
  //   };
    
  //   axios(config)
  //   .then(function (response) {
  //     if(response.data.status === 'OK'){
  //       // alert('login success')
  //     } else{
  //       alert('login failed')
  //       localStorage.removeItem('token')
  //       window.location = '/login'
  //     }
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  //   }, []);


  return (
    <div>
      <Navbar />
      <Sidebar/>
      <Footer />
    </div>
  );
};
export default Homeline;
